/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import App from './App.vue'

// Vuesax Component Framework
import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css' //Material Icons
import 'font-awesome/css/font-awesome.min.css'
import 'vuesax/dist/vuesax.css'; // Vuesax
import VueRadioToggleButtons from 'vue-radio-toggle-buttons';
import 'vue-radio-toggle-buttons/dist/vue-radio-toggle-buttons.css';
Vue.use(Vuesax)
Vue.use(VueRadioToggleButtons);



// axios
import axios from "./axios.js"

Vue.prototype.$http = axios


import helpers from './helpers/general';

Vue.use({
  install() {
    Vue.helpers = helpers;
    Vue.prototype.$helpers = helpers;
  }
});

/* usage inside components: */
//this.$helpers.foo()
/* usage anywhere else: */
//Vue.helpers.foo()

// API Calls
import "./http/requests"

// mock
import "./fake-db/index.js"

// Theme Configurations
import '../themeConfig.js'


// Firebase
import '@/firebase/firebaseConfig'


// Auth0 Plugin
import AuthPlugin from "./plugins/auth";

Vue.use(AuthPlugin);


// ACL
import acl from './acl/acl'


// Globally Registered Components
import './globalComponents.js'


// Styles: SCSS
import './assets/scss/main.scss'


// Tailwind
import '@/assets/css/main.css'


// Vue Router
import router from './router'


// Vuex Store
import store from './store/store'


// i18n
import i18n from './i18n/i18n'


// Vuexy Admin Filters
import './filters/filters'


// Clipboard
import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard);


// Tour
import VueTour from 'vue-tour'

Vue.use(VueTour)
// require('vue-tour/dist/vue-tour.css')


// VeeValidate
import VeeValidate from 'vee-validate';
import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate';

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
Vue.use(VeeValidate);


// Google Maps

import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
  load: {
    // key: 'AIzaSyCXjDnXOisyAbIPsSvwRIWLDp9ZDuqBk30',
    key: 'AIzaSyD2_dLcglU5iyLUQ9hYiUuvpAyy7iRT3-o',
    libraries: 'places', // This is required if you use the Autocomplete plugin
  },
});

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer'

Vue.use(VueHammer)


// PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'


// Feather font icon
require('./assets/css/iconfont.css')


// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';

// Lightbox
import VueLazyLoad from 'vue-lazyload'

Vue.use(VueLazyLoad)

// Hotjar
import Hotjar from 'vue-hotjar';
Vue.use(Hotjar, {
  id: '2953409',
  isProduction: true,
  snippetVersion: 6
})


import "vue-virtual-scroller/dist/vue-virtual-scroller.css"
import VueVirtualScroller from 'vue-virtual-scroller';

Vue.use(VueVirtualScroller);

Vue.config.productionTip = false

import Paginate from "vuejs-paginate";

Vue.component("paginate", Paginate);

Vue.prototype.$defaultImage = require('./assets/images/default.jpg')
Vue.prototype.$defaultProfileImage = require('./assets/images/personhood/default-profile.jpg')
Vue.prototype.$defaultVideoImage = require('./assets/images/personhood/no-img.png')
Vue.prototype.$defaultIconTick = require('./assets/images/icon-tick.svg')
Vue.prototype.$defaultIconMinus = require('./assets/images/icon-minus.svg')

new Vue({
  router,
  store,
  i18n,
  acl,
  render: h => h(App)
}).$mount('#app')
