import axios from "../../../axios/index.js";
import store from "../../../../store/store.js";
import router from "./../../../../router";
import authService from "../../..//../auth/authService";
// Token Refresh
let isAlreadyFetchingAccessToken = false;
let subscribers = [];
let stopPendingRequest = false;

function onAccessTokenFetched(refreshData) {
  // subscribers = subscribers.filter(callback => callback(refreshData))
  localStorage.setItem(
    `${process.env.VUE_APP_ACCESS_TOKEN_NAME}accessToken`,
    refreshData.data.data.accessToken
  );
  localStorage.setItem(
    `${process.env.VUE_APP_ACCESS_TOKEN_NAME}refreshToken`,
    refreshData.data.data.refreshToken
  );
  localStorage.setItem(
    `${process.env.VUE_APP_ACCESS_TOKEN_NAME}LoggedIn`,
    "true"
  );
  const appLoading = document.getElementsByClassName("con-vs-loading");
  for (let i = 0; i < appLoading.length; i++) {
    appLoading[i].style.display = "none";
  }
  // store.commit("UPDATE_USER_INFO", refreshData.user, { root: true });
  // store.commit("SET_BEARER", refreshData.data.data.accessToken);
}

function addSubscriber(callback) {
  subscribers.push(callback);
}

function onRefreshed() {
  subscribers.map(cb => cb());
}

export default {
  init() {
    axios.interceptors.request.use(
      config => {
        const token = localStorage.getItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}accessToken`
        );
        if (token) {
          config.headers.Authorization = token;
        }
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      function(response) {
        return response;
      },
      function(error) {
        if (!error.response) {
          return Promise.reject({
            message: "Please check your internet connection."
          });
        }
        const {
          config,
          response: { status }
        } = error;

        if (
          config.url.includes("login") &&
          (status === 401 || status === 410 || status === 422)
        ) {
          return Promise.reject({ message: error.response.data.message });
        }

        if (stopPendingRequest) {
          stopPendingRequest = false;
          return Promise.reject(false);
        }

        if (
          config.url.includes("refreshToken") &&
          (status === 410 || status === 422)
        ) {
          localStorage.removeItem(
            `${process.env.VUE_APP_ACCESS_TOKEN_NAME}accessToken`
          );
          localStorage.removeItem(
            `${process.env.VUE_APP_ACCESS_TOKEN_NAME}refreshToken`
          );
          localStorage.removeItem(
            `${process.env.VUE_APP_ACCESS_TOKEN_NAME}userInfo`
          );
          stopPendingRequest = true;
          isAlreadyFetchingAccessToken = false;
          router.push("/login");
          return Promise.reject(false);
        }

        if (config.url.includes("refreshToken") && status === 401) {
          // console.log('refresh token and 401')
          if (config.url.includes("/admin/")) {
            router.push("/admin/login");
          } else if (config.url.includes("/teacher/")) {
            router.push("/teacher/login");
          }
        }

        const originalRequest = config;
        if (status === 401) {
          // if (response && response.status === 401) {
          if (!isAlreadyFetchingAccessToken) {
            const refreshToken = refreshToken;
            isAlreadyFetchingAccessToken = true;
            store
              .dispatch("auth/fetchAccessToken")
              .then(refreshToken => {
                isAlreadyFetchingAccessToken = false;
                onAccessTokenFetched(refreshToken);
              })
              .catch(err => {
                isAlreadyFetchingAccessToken = false;
              });
          }
          subscribers = [];
          const retryOriginalRequest = new Promise(resolve => {
            addSubscriber(access_token => {
              originalRequest.headers.Authorization = "Bearer " + access_token;
              resolve(axios(originalRequest));
            });
          });

          const appLoading = document.getElementsByClassName("con-vs-loading");
          if (appLoading[0]) {
            appLoading[0].style.display = "none";
          }
          onRefreshed();
          return retryOriginalRequest;
        }
        const appLoading = document.getElementsByClassName("con-vs-loading");
        if (appLoading[0]) {
          appLoading[0].style.display = "none";
        }
        return Promise.reject(error);
      }
    );
  },
  login(email, pwd, route) {
    return axios.post("/auth/login", {
      email: email,
      password: pwd,
      route: route
    });
  },
  forgotPassword(email) {
    return axios.post("/auth/forgotPassword", { email: email });
  },
  registerUser(name, email, pwd) {
    return axios.post("/auth/register", {
      displayName: name,
      email: email,
      password: pwd
    });
  },
  refreshToken() {
    return axios.post("/auth/refreshToken", {
      refreshToken: localStorage.getItem(
        `${process.env.VUE_APP_ACCESS_TOKEN_NAME}refreshToken`
      )
    });
  },
  checkTokenExpiry(token) {
    return axios.post("/general/checkTokenExpiry", { token });
  },
  resetPassword(password, token) {
    return axios.post("/auth/resetPassword", {
      password: password,
      token: token
    });
  },
  //admin management routes
  fetchAdmins() {
    return axios.get("/admin/get-admin-users");
  },
  resetAdminPassword(userId) {
    return axios.get(`/admin/reset-password/${userId}`);
  },
  saveAdmin(user) {
    return axios.post(`/admin/store-admin-user`, user);
  },
  removeAdmin(userId) {
    return axios.get(`/admin/delete-admin/${userId}`);
  },
  getAdminById(userId) {
    return axios.get(`/admin/get-user-by-id/${userId}`);
  },
  updateAdmin(user) {
    return axios.post(`/admin/update-admin-users`, user);
  },
  changePassword(data) {
    return axios.post("/admin/change-admin-password", data);
  },

  updateProfile(data) {
    return axios.post("/admin/update-profile", data);
  },

  //age groups
  getAgeGroups() {
    return axios.get("/admin/age-group/list");
  },
  saveAgeGroup(data) {
    return axios.post(`/admin/age-group/store`, data);
  },
  deleteAgeGroup(ageGroup) {
    return axios.get(`/admin/age-group/delete/${ageGroup}`);
  },
  /*  getAgeGroupById(ageGroup) {
      return axios.get(`/admin/age-group/detail/${ageGroup}`)
    },*/
  editAgeGroup(data) {
    return axios.post(`/admin/age-group/update`, data);
  },

  /*core skill development*/

  addCoreSkill(data, config) {
    return axios.post("admin/save-core-skill", data, config);
  },

  updateCoreSkill(coreSkillId, data, config) {
    return axios.post("/admin/update-core-skill/" + coreSkillId, data, config);
  },
  deleteCoreSkill(coreSkillId) {
    return axios.get("/admin/delete-core-skill/" + coreSkillId);
  },

  deleteCoreSkillDevelopment(data) {
    return axios.post(
      "/admin/professional-development/delete-core-skills-development/",
      data
    );
  },

  getCoreSkillDevelopment(filter) {
    return axios.post(
      "/admin/professional-development/get-core-skills-development/",
      filter
    );
  },

  fetchCoreSkills() {
    return axios.get("/admin/get-core-skills/");
  },
  /*For parent*/
  getParentCoreSkillWithActivityCount(data) {
    return axios.get(
      "/parent/core-skill-activity/" + data.roomId + "/" + data.childId
    );
  },
  getCoreSkillWithActivityCount(data) {
    return axios.get(
      "/teacher/core-skill-activity-count/" +
        data.roomId +
        "?childId=" +
        data.childId
    );
  },
  updateCoreSkillDevelopment(data) {
    return axios.post(
      `/admin/professional-development/update-core-skills-development`,
      data
    );
  },
  storeCoreSkillDevelopment(data) {
    return axios.post(
      `/admin/professional-development/store-core-skills-development`,
      data
    );
  },

  logProfessionalDevelopment(log) {
    return axios.post(`/teacher/log-professional-development/`, log);
  },

  logProfessionalDevelopmentResource(log) {
    return axios.post(`/teacher/log-professional-development-resource/`, log);
  },

  logProfessionalDevelopmentForTeachers(log) {
    return axios.post(
      `/teacher/log-professional-development-for-teachers/`,
      log
    );
  },

  logFastCheckProfessionalDevelopmentForTeacher(log) {
    return axios.post(
      `/teacher/log-professional-development-fast-check-for-teachers/`,
      log
    );
  },

  /*
    EYLF Category
  */
  getEYLFCategory(params) {
    return axios.get(
      "admin/early-years-learning-category?search=" +
        params.search +
        "&sort=" +
        params.sort
    );
  },
  deleteEylfCategoryById(id) {
    return axios.delete("admin/early-years-learning-category/" + id);
  },
  getEYLFCategoryWithFrameworkByCenterId(centerId) {
    return axios.get("teacher/documentation/eylf-checklist/" + centerId);
  },
  addEYLFCategory(data) {
    return axios.post("admin/early-years-learning-category", data);
  },
  editEYLFCategory(data) {
    return axios.patch(
      "admin/early-years-learning-category/" + data.id,
      data.data
    );
  },
  getEYLFCategoryByCountryId(id) {
    return axios.get("admin/early-years-learning-category/country/" + id);
  },
  getEYLFCategoryByStateId(id) {
    return axios.get("admin/early-years-learning-category/state/" + id);
  },
  addFastCheck(data) {
    return axios.post("admin/save-fast-check", data);
  },
  getEYLFCategoryForTeacher(centerId) {
    return axios.get(
      "teacher/early-years-learning-category/learning-center/" + centerId
    );
  },
  getEYLFCategoryByMultipleCenterId(centerIds) {
    return axios.get(
      "teacher/early-years-learning-category/multi-learning-center/" + centerIds
    );
  },
  updateFastCheck(fastCheckId, data) {
    return axios.post("/admin/update-fast-check/" + fastCheckId, data);
  },
  deleteFastCheck(fastCheckId) {
    return axios.get("/admin/delete-fast-check/" + fastCheckId);
  },
  storeChildFastCheck(data) {
    return axios.post("/teacher/child-fast-check", data);
  },
  getChildFastCheckByChildIdAndChildFastCheckId(data) {
    return axios.get(
      "/teacher/child-fast-check/" + data.childId + "/" + data.childFastCheckId
    );
  },
  getChildFastCheckByChildFastCheckId(childFastCheckId) {
    return axios.get("/teacher/child-fast-check/" + childFastCheckId);
  },
  getTeacherById(teacherId) {
    return axios.get("/teacher/detail/" + teacherId);
  },
  getDirectorSubscriptionByTeacherId(teacherId) {
    return axios.get(
      "/teacher/get-director-subscription-by-teacher-id/" + teacherId
    );
  },
  fetchFastChecks() {
    return axios.get("/admin/get-fast-checks/");
  },
  getRandomFastChecks(ageGroupId) {
    return axios.get("/admin/get-random-fast-checks/" + ageGroupId);
  },
  deleteChildFromChildFastCheck(data) {
    return axios.delete(
      "/teacher/child-fast-check/remove/child/" +
        data.childFastCheckId +
        "/" +
        data.childId
    );
  },
  getFastChecksDevelopment(filter) {
    return axios.post(
      "/admin/professional-development/get-fast-checks-development",
      filter
    );
  },
  deleteFastCheckDevelopment(data) {
    return axios.post(
      "/admin/professional-development/delete-fast-check-development/",
      data
    );
  },

  updateFastCheckDevelopment(data) {
    return axios.post(
      `/admin/professional-development/update-fast-check-development`,
      data
    );
  },
  storeFastCheckDevelopment(data) {
    return axios.post(
      `/admin/professional-development/store-fast-check-development`,
      data
    );
  },

  getFastCheckCompare(data) {
    if (data.selectedTeachers && data.selectedTeachers.length > 0) {
      return axios.get(
        "teacher/fast-check-compare/" +
          data.roomId +
          "/" +
          data.teacherId +
          "/" +
          data.childId +
          `?teacherIds=${JSON.stringify(data.selectedTeachers)}`
      );
    }
    return axios.get(
      "teacher/fast-check-compare/" +
        data.roomId +
        "/" +
        data.teacherId +
        "/" +
        data.childId
    );
  },
  getDirectorFastCheckCompare(query) {
    return axios.get("teacher/director-fast-check-compare?query=" + query);
  },
  getCenterAdminFastCheckCompare(query) {
    return axios.get("teacher/centerAdmin-fast-check-compare?query=" + query);
  },

  getCoreskillById(id) {
    return axios.get("teacher/core-skill/" + id);
  },

  /*eylf*/
  getEylfSets(data) {
    return axios.get(
      "admin/early-years-learning-framework/elyf-sets?page=" +
        data.page +
        "&limit=" +
        data.limit +
        "&search=" +
        data.search +
        "&sort=" +
        data.sort +
        "&dir=" +
        data.dir +
        "&country=" +
        data.country +
        "&state=" +
        data.state
    );

    //return axios.post('admin/early-years-learning-framework/elyf-sets',data)
  },

  getEylfSetById(eylfSetId) {
    return axios.get(
      "admin/early-years-learning-framework/get-set/" + eylfSetId
    );
  },
  getEylfs(stateId, categoryId) {
    return axios.get(
      "admin/early-years-learning-framework/" + stateId + "/" + categoryId
    );
  },
  storeEylf(data) {
    return axios.post("admin/early-years-learning-framework/store-eylf", data);
  },
  addEylfSet(data) {
    return axios.post(
      "admin/early-years-learning-framework/store-eylf-set",
      data
    );
  },
  updateEylf(data) {
    return axios.post("admin/early-years-learning-framework/update/", data);
  },
  deleteEylf(eylfId) {
    return axios.get("/admin/early-years-learning-framework/delete/" + eylfId);
  },

  /*Country*/
  getAllCountries() {
    return axios.get(`/admin/get-all-countries`);
  },

  getAllStates(data) {
    return axios.post(
      `/admin/early-years-learning-framework/get-all-states`,
      data
    );
  },

  getStateInfo(stateId) {
    return axios.get(
      "/admin/early-years-learning-framework/get-state-by-id/" + stateId
    );
  },

  addState(data) {
    return axios.post("/admin/early-years-learning-framework/add-state/", data);
  },
  deleteStateByStateId(data) {
    return axios.get(
      "/admin/early-years-learning-framework/delete-state/" +
        data.countryId +
        "/" +
        data.stateId
    );
  },
  /*EYLF SET DELETE*/
  removeEYLFSetById(setId) {
    return axios.delete("/admin/early-years-learning-framework-set/" + setId);
  },

  /*Streams*/
  addStream(data, config) {
    return axios.post("admin/save-stream", data, config);
  },

  updateStream(streamId, data, config) {
    return axios.post("/admin/update-stream/" + streamId, data, config);
  },

  deleteStream(streamId) {
    return axios.get("admin/delete-stream/" + streamId);
  },

  fetchStreams() {
    return axios.get("admin/get-streams");
  },

  /*For parent*/
  getParentStreamWithActivityCount(data) {
    return axios.get(
      "/parent/stream-activity/" + data.roomId + "/" + data.childId
    );
  },
  getStreamWithActivityCount(data) {
    return axios.get(
      "/teacher/stream-activity-count/" +
        data.roomId +
        "?childId=" +
        data.childId
    );
  },
  /*Ways of learning*/
  addWaysOfLearning(data, config) {
    return axios.post("admin/save-ways-of-learning", data, config);
  },

  updateWaysOfLearning(waysOfLearningId, data, config) {
    return axios.post(
      "/admin/update-ways-of-learning/" + waysOfLearningId,
      data,
      config
    );
  },

  deleteWaysOfLearning(waysOfLearningId) {
    return axios.get("admin/delete-ways-of-learning/" + waysOfLearningId);
  },

  fetchWaysOfLearning() {
    return axios.get("admin/get-ways-of-learning");
  },

  /*For parent*/
  getParentWaysOfLearningWithActivityCount(data) {
    return axios.get(
      "/parent/ways-of-learning-activity/" + data.roomId + "/" + data.childId
    );
  },
  getWaysOfLearningWithActivityCount(data) {
    return axios.get(
      "/teacher/ways-of-learning-activity-count/" +
        data.roomId +
        "?childId=" +
        data.childId
    );
  },

  /* children's Stories routes */
  fetchChildrenStories(id, data) {
    return axios.get(
      "/admin/children-stories/list/" +
        id +
        "?page=" +
        data.page +
        "&limit=" +
        data.limit +
        "&search=" +
        data.search +
        "&sort=" +
        data.sort +
        "&dir=" +
        data.dir
    );
  },
  deleteQuestion(data) {
    return axios.get(
      "/admin/children-stories/" + data.questionId + "/delete/" + data.sId
    );
  },
  saveStory(data) {
    return axios.post("/admin/children-stories/create", data);
  },
  editStory(data) {
    return axios.post(
      "/admin/children-stories/" + data.id + "/edit",
      data.details
    );
  },
  fetchChildrenStoriesSet(data) {
    return axios.get(
      "/admin/children-stories/set/list?page=" +
        data.page +
        "&limit=" +
        data.limit +
        "&search=" +
        data.search +
        "&sort=" +
        data.sort +
        "&dir=" +
        data.dir
    );
  },
  getLearningCentre() {
    return axios.get("/admin/children-stories/center/list");
  },
  saveStorySet(data) {
    return axios.post("/admin/children-stories/set/create", data);
  },
  editStorySet(data) {
    return axios.post(
      "/admin/children-stories/set/" + data.id + "/edit",
      data.details
    );
  },
  deleteQuestionSet(id) {
    return axios.get("/admin/children-stories/set/" + id + "/delete");
  },
  /*Teacher child story*/

  getAllNotifications(userId) {
    return axios.get("/teacher/notification/list/" + userId);
  },
  changeNotificationsRead(notificationId) {
    return axios.get(
      "/teacher/notification/change-notification-status/" + notificationId
    );
  },

  getChildStories(childId) {
    return axios.get("teacher/get-child-story-by-child-id/" + childId);
  },

  getChildById(id) {
    return axios.get("teacher/child-info/" + id);
  },
  /*Get child list by child ids*/
  fetchChildListByIds(childIds, parentId) {
    return axios.get(
      "parent/child-list/parent/" +
        parentId +
        "/child/" +
        JSON.stringify(childIds)
    );
  },
  saveTaggedChildren(data) {
    return axios.post("teacher/child/saveTaggedChildren", data);
  },
  getActivityPhotosByActivityId(activityId) {
    return axios.get("teacher/activity/getPhotos/" + activityId);
  },
  getChildStoryByStoryId(id) {
    return axios.get("teacher/get-child-story-by-story-id/" + id);
  },

  getPublishedChildStoryByChildId(id) {
    return axios.get("parent/get-published-child-story-by-child-id/" + id);
  },
  approveChildStory(data) {
    return axios.post("parent/approve-child-story/", data);
  },
  rejectChildStory(storyId) {
    return axios.get("parent/reject-child-story/" + storyId);
  },

  saveChildStoryComment(data) {
    return axios.post("/teacher/child-story/add-child-story/", data);
  },
  /*
  Activity routes
  */
  fetchLearningStoryForDashboard(params) {
    return axios.get("teacher/learning-story-for-dashboard" + params);
  },

  /*
  Activity routes
  */
  fetchActiveLearningStoryForDashboard(params) {
    return axios.get("teacher/active-learning-story-for-dashboard" + params);
  },
  /*Fetch weekly activity by status*/
  getActivityGrowthData(roomId, childId) {
    console.log('childId = ', childId);
    return axios.get("teacher/core-skill/activity-growth/" + roomId +"?childId=" + childId);
  },

  /*
    get Director Activity Growth Data
  */
  getDirectorActivityGrowthData(query) {
    return axios.get(
      "teacher/core-skill/director-activity-growth?query=" + query
    );
  },
  /*
   get Center Admin Activity Growth Data
 */
  getCenterAdminActivityGrowthData(query) {
    return axios.get(
      "teacher/core-skill/director-activity-growth?query=" + query
    );
  },
  removeFollowUp(data) {
    return axios.post(
      "/teacher/activity/remove-follow-up/" + data.parentActivityId,
      data.child
    );
  },
  getWeeklyActivityBySlug(roomId, queryParams = "") {
    return axios.get("teacher/activity/weekly-status/" + roomId + queryParams);
  },
  getDirectorWeeklyActivityBySlug(data) {
    return axios.get("teacher/activity/director/weekly-status?query=" + data);
  },
  getCenterAdminWeeklyActivityBySlug(data) {
    return axios.get(
      "teacher/activity/centerAdmin/weekly-status?query=" + data
    );
  },
  getOutcomeActivities(data) {
    return axios.get(
      "teacher/daily-diary/outcome-activity/" + data.roomId + data.param
    );
  },
  getOutcomeActivitiesForLeadTeacher(data) {
    return axios.get(
      "teacher/daily-diary/outcome-activity-for-lead-teacher/" +
        data.roomId +
        data.param
    );
  },

  getPlannedActivities(data) {
    return axios.get(
      "teacher/daily-diary/plan-activity/" + data.roomId + data.param
    );
  },

  getPlannedActivitiesForTeacher(data) {
    return axios.get(
      "teacher/daily-diary/plan-activity-for-teacher/" +
        data.roomId +
        data.param
    );
  },

  getActivities(params) {
    return axios.get("teacher/activity" + params);
  },

  getOnlyActivities(params) {
    return axios.get("teacher/activity-only" + params);
  },

  getActivityById(activityId) {
    return axios.get("teacher/activity/" + activityId);
  },
  saveActivity(data) {
    return axios.post("teacher/activity", data.obj, data.config);
  },
  editActivityDetail(data) {
    return axios.put(
      "teacher/activity/detail/" + data.activityId,
      data.obj,
      data.header
    );
  },
  getActivityByLikeName(data) {
    let learningCenterId = "";
    if (data.learningCenterId) {
      learningCenterId = data.learningCenterId;
    }
    return axios.get(
      "teacher/activity-by-name?learningCenterId=" +
        learningCenterId +
        "&name=" +
        data.inputValue
    );
  },
  addChildrenToActivity(data) {
    return axios.post(
      "teacher/activity/add-children/" + data.activityId,
      data.children
    );
  },
  updateFavouriteStatus(activityId) {
    return axios.put("teacher/activity/update-favourite/" + activityId);
  },
  editActivity(data) {
    return axios.put(
      "teacher/activity/" + data.activityId,
      data.obj,
      data.header
    );
  },
  addImageToActivity(data) {
    return axios.post(
      "/teacher/activity/upload-image/" + data.activityId,
      data.obj,
      data.header
    );
  },
  removeImageFromActivityById(data) {
    return axios.delete(
      "teacher/activity/remove-image/" + data.activityId + "/" + data.imageId
    );
  },
  getFavouriteActivities(roomId) {
    return axios.get("teacher/activity/favourite/room/" + roomId);
  },
  addActivityToPlanner(activityId) {
    return axios.get("teacher/activity/favourite/add-to-planner/" + activityId);
  },
  removeActivityById(activityId) {
    return axios.delete("teacher/activity/" + activityId);
  },
  updateFollowUp(data) {
    return axios.put(
      "teacher/activity/follow-up/" + data.activityId,
      data.obj,
      data.header
    );
  },
  /*update follow up status*/
  updateFollowUpStatus(data) {
    return axios.put(
      "teacher/activity/follow-up/status/" + data.activityId,
      data.obj
    );
  },
  /*Update Profile*/
  updateTeacherProfile(data) {
    return axios.post("/teacher/update-profile", data);
  },
  /*update teacher password*/
  changeTeacherPassword(data) {
    return axios.post("/teacher/change-password", data);
  },

  /*Add Child Fast Check*/
  addChildFastCHeck(data) {},
  /* Support Routes */
  fetchSupportList(data) {
    return axios.get(
      "/admin/support/list?page=" +
        data.page +
        "&limit=" +
        data.limit +
        "&search=" +
        data.search +
        "&sort=" +
        data.sort +
        "&dir=" +
        data.dir
    );
  },
  fetchEnquiryDetails(id) {
    return axios.get("/admin/support/details/" + id);
  },
  resolveSupportEnquiry(id) {
    return axios.post("/admin/support/resolve/" + id);
  },

  /*Groups*/
  countGroup() {
    return axios.get("/admin/group/count");
  },
  addGroup(data) {
    return axios.post("/admin/group/store", data);
  },
  updateGroup(data) {
    return axios.post("/admin/group/update/", data);
  },
  fetchGroups(filter) {
    return axios.post("/admin/group/list", filter);
  },
  deleteGroup(groupId) {
    return axios.get("/admin/group/delete/" + groupId);
  },

  getGroupById(groupId) {
    return axios.get("/admin/group/details/" + groupId);
  },
  editGroupStatus(data) {
    return axios.patch(
      "/admin/group/status/" +
        data.groupId +
        "?accountStatus=" +
        data.accountStatus
    );
  },
  getCentersOfGroup(data) {
    return axios.post("/admin/group/center", data);
  },

  /*Center*/

  countCenter() {
    return axios.get("/admin/center/count");
  },
  addCenter(data) {
    return axios.post("/admin/center/store", data);
  },
  updateCenterData(data) {
    return axios.post("/admin/center/update", data);
  },
  deleteCenter(centerId) {
    return axios.get("/admin/center/delete/" + centerId);
  },
  fetchLearningCentreList(data) {
    return axios.get(
      "/admin/learningCentre/list?page=" +
        data.page +
        "&limit=" +
        data.limit +
        "&search=" +
        data.search +
        "&sort=" +
        data.sort +
        "&dir=" +
        data.dir +
        "&country=" +
        data.country +
        "&state=" +
        data.state
    );
  },
  getCenterDetailsById(centerId) {
    return axios.get("/admin/center/details/" + centerId);
  },
  getCenterAdmin(centerId) {
    return axios.get("/admin/center/adminList/" + centerId);
  },
  getCenterNumberList(centerId) {
    return axios.get("/admin/center/numberList/" + centerId);
  },
  editCenterStatus(centerId) {
    return axios.patch("/admin/center/status/" + centerId);
  },
  addAdminCenter(data) {
    return axios.post("/admin/center/addAdmin/" + data.centerId, data.details);
  },
  getCenterAdminDetails(id) {
    return axios.get("/admin/center/admin/details/" + id);
  },
  editCenterAdminDetails(data) {
    return axios.post(
      "/admin/center/editAdmin/" + data.details._id,
      data.details
    );
  },
  getCenterTeacherList(id) {
    return axios.get("/admin/center/teachers/list/admin/" + id);
  },
  getCenterChildrenList(id) {
    return axios.get("/admin/center/children/list/admin/" + id);
  },
  getCenterRoomsList(id) {
    return axios.get("/admin/center/rooms/list/admin/" + id);
  },
  getDirector() {
    return axios.get("/admin/center/getDirectorList");
  },
  getRegionCentre(data) {
    return axios.get(
      "/director/getCentreByRegion?state=" +
        data.state +
        "&country=" +
        data.country
    );
  },
  getCountryCentre(data) {
    return axios.get("/director/getCentreByCountry?country=" + data.country);
  },
  /* Page Management Routes */
  fetchPageList(data) {
    return axios.get(
      "/admin/pages?page=" +
        data.page +
        "&limit=" +
        data.limit +
        "&search=" +
        data.search +
        "&sort=" +
        data.sort +
        "&dir=" +
        data.dir
    );
  },
  fetchPageDetails(id) {
    return axios.get("/admin/pages/" + id);
  },
  editPage(data) {
    return axios.post("/admin/pages/update/" + data.id, data.info);
  },
  fetchPage(slug) {
    return axios.get("/page/findWithSlug?slug=" + slug);
  },
  getContentBySlug(slug) {
    return axios.get("/page/findContentWithSlug?slug=" + slug);
  },
  getFaqData() {
    return axios.get("/page/getFaqList");
  },
  getPackage(slug) {
    return axios.get("/page/findPackageBySlug?slug=" + slug);
  },
  submitInfo(data) {
    return axios.post("/page/submitContactForm", data);
  },
  getInclusionData() {
    return axios.get("/page/fetchInclusionList");
  },
  getUSPList() {
    return axios.get("/page/usp");
  },
  paypalApi(data) {
    return axios.post("/director/paypal/pay", data);
  },
  packageSelection(payload) {
    return axios.post("/director/package-select", payload);
  },
  upgradePackage(previousPackage, selectedPackage) {
    return axios.post("/director/package-upgrade", selectedPackage);
  },
  addUpdateCancelDateForSubscription(payload) {
    return axios.post("/director/add-update-end-date", payload);
  },
  addUpdateCancelDateForSubscriptionByAdmin(payload) {
    return axios.post("/admin/add-update-end-date-by-admin", payload);
  },

  /* User Subscriptions Routes */
  fetchUserSubscriptionsList(data) {
    return axios.get(
      "/admin/userSubscriptions/list?page=" +
        data.page +
        "&limit=" +
        data.limit +
        "&search=" +
        data.search +
        "&sort=" +
        data.sort +
        "&dir=" +
        data.dir +
        "&status=" +
        data.status
    );
  },
  fetchUserSubscriptionsDetails(id) {
    return axios.get("/admin/userSubscriptions/" + id);
  },
  editUserSubscriptions(data) {
    return axios.post("/admin/userSubscriptions/update/" + data.id, data.info);
  },
  getPastSubscription(id) {
    return axios.get("/admin/userSubscriptions/pastSubscription/" + id);
  },
  cancelSubscription(id) {
    return axios.delete("/admin/userSubscriptions/cancel/" + id);
  },
  fetchPackage() {
    return axios.get("/admin/subscriptionPackage/list");
  },
  savePackage(data) {
    return axios.post("/admin/userSubscription/create", data);
  },

  /*self assessment*/
  addSelfAssessment(data) {
    return axios.post("/teacher/self-assessment/add", data);
  },

  addSelfAssessmentFastCheck(data) {
    return axios.post("/teacher/self-assessment-fast-check/add", data);
  },

  getAssessments(teacherId) {
    return axios.get("teacher/assessment/list/" + teacherId);
  },

  getAssessmentsFastCheck(teacherId) {
    return axios.get("teacher/assessment-fast-check/list/" + teacherId);
  },

  /*getDocumentViewLogs() {
    return axios.get('teacher/assessment/documentLogs');
  },*/
  getDocumentViewLogs(data) {
    return axios.get(
      "teacher/assessment/documentLogs?page=" +
        data.page +
        "&limit=" +
        data.limit +
        "&teacherId=" +
        data.teacherId
    );
  },

  getDocumentViewLogsFastCheck(data) {
    return axios.get(
      "teacher/assessment/documentLogs-fast-check?page=" +
        data.page +
        "&limit=" +
        data.limit +
        "&teacherId=" +
        data.teacherId
    );
  },
  saveViewNote(data) {
    return axios.post("teacher/assessment/saveNote", data);
  },

  saveViewNoteFastCheck(data) {
    return axios.post("teacher/assessment/saveNote-fast-check", data);
  },
  /* Settings */
  // Email Template Management
  fetchEmailTemplateList(data) {
    return axios.get(
      "/admin/emailTemplate/list?page=" +
        data.page +
        "&limit=" +
        data.limit +
        "&search=" +
        data.search +
        "&sort=" +
        data.sort +
        "&dir=" +
        data.dir
    );
  },
  fetchEmailTemplateDetails(id) {
    return axios.get("/admin/emailTemplate/details/" + id);
  },
  updateEmailTemplateDetails(data) {
    return axios.post("/admin/emailTemplate/update/" + data.id, data.info);
  },
  //content management
  fetchContentList(data) {
    return axios.get(
      "/admin/settings/content/list?page=" +
        data.page +
        "&limit=" +
        data.limit +
        "&search=" +
        data.search +
        "&sort=" +
        data.sort +
        "&dir=" +
        data.dir
    );
  },
  updateContentDetails(data) {
    return axios.post("/admin/settings/content/update", data);
  },
  // unique selling point
  fetchUspList(data) {
    return axios.get(
      "/admin/settings/usp/list?page=" +
        data.page +
        "&limit=" +
        data.limit +
        "&search=" +
        data.search +
        "&sort=" +
        data.sort +
        "&dir=" +
        data.dir
    );
  },
  updateUspDetails(data) {
    return axios.post("/admin/settings/usp/update", data);
  },
  // testimonials
  fetchTestimonialList(data) {
    return axios.get(
      "/admin/settings/testimonial/list?page=" +
        data.page +
        "&limit=" +
        data.limit +
        "&search=" +
        data.search +
        "&sort=" +
        data.sort +
        "&dir=" +
        data.dir
    );
  },
  updateTestimonialDetails(data) {
    return axios.post("/admin/settings/testimonial/update", data);
  },
  createTestimonial(data) {
    return axios.post("/admin/settings/testimonial/create", data);
  },
  removeTestimonial(id) {
    return axios.get("/admin/settings/testimonial/delete/" + id);
  },
  fetchInclusionList(data) {
    return axios.get(
      "/admin/settings/subscription/inclusion/list?page=" +
        data.page +
        "&limit=" +
        data.limit +
        "&search=" +
        data.search +
        "&sort=" +
        data.sort +
        "&dir=" +
        data.dir
    );
  },
  updateInclusionDetails(data) {
    return axios.post("/admin/settings/subscription/inclusion/update", data);
  },
  removeInclusion(id) {
    return axios.get("/admin/settings/subscription/inclusion/delete/" + id);
  },
  createInclusion(data) {
    return axios.post("/admin/settings/subscription/inclusion/create", data);
  },
  removeTutorial(id) {
    return axios.get(`/admin/tutorial/delete/${id}`);
  },
  createTutorial(data) {
    return axios.post("/admin/tutorial/create", data);
  },
  updateTutorialDetails(data) {
    return axios.post("/admin/tutorial/update", data);
  },
  // FAQ
  fetchFaqList(data) {
    return axios.get(
      "/admin/settings/faq/list?page=" +
        data.page +
        "&limit=" +
        data.limit +
        "&search=" +
        data.search +
        "&sort=" +
        data.sort +
        "&dir=" +
        data.dir
    );
  },
  updateFaqDetails(data) {
    return axios.post("/admin/settings/faq/update", data);
  },
  createFaq(data) {
    return axios.post("/admin/settings/faq/create", data);
  },
  removeFaq(id) {
    return axios.get("/admin/settings/faq/delete/" + id);
  },

  /** about us */
  getAdminAboutUsList(data) {
    return axios.get("/admin/get-about-us-list?search=" + data);
  },
  getLearningCentreAdmin() {
    return axios.get("/admin/get-center-list");
  },
  saveAboutUsAdmin(data) {
    return axios.post("/admin/create-about-us", data);
  },
  getAdminAboutUsDetails(id) {
    return axios.get("/admin/get-about-us/" + id);
  },
  ImageUploadAdmin(data) {
    return axios.post("/admin/uploadImage", data.obj, data.header);
  },
  updateAdminAboutUs(data) {
    return axios.post("/admin/update-about-us/" + data.id, data.data);
  },

  //Philosophies
  getAdminPhilosophiesList(data) {
    return axios.get("/admin/get-philosophies-list?search=" + data);
  },
  savePhilosophiesAdmin(data) {
    return axios.post("/admin/create-philosophies", data);
  },
  getAdminPhilosophiesDetails(id) {
    return axios.get("/admin/get-philosophies/" + id);
  },
  updatePhilosophiesAdmin(data) {
    return axios.post("/admin/update-philosophies/" + data.id, data.data);
  },

  getPhilosophiesByCenter(centerId) {
    return axios.get("/parent/get-philosophies-by-center/" + centerId);
  },

  //pricing page subscription package
  fetchSubscriptionList(data) {
    return axios.get(
      "/admin/settings/subscription/list?page=" +
        data.page +
        "&limit=" +
        data.limit +
        "&search=" +
        data.search +
        "&sort=" +
        data.sort +
        "&dir=" +
        data.dir
    );
  },
  updateSubscriptionDetails(data) {
    return axios.post("/admin/settings/subscription/update", data);
  },
  fetchDirectorSubscriptionList(data) {
    return axios.get(
      "/director/subscription/list?page=" +
        data.page +
        "&limit=" +
        data.limit +
        "&search=" +
        data.search +
        "&sort=" +
        data.sort +
        "&dir=" +
        data.dir
    );
  },
  getLandingTestimonialList() {
    return axios.get("/page/testimonial");
  },
  fetchDirectorSubscription() {
    return axios.get("/director/subscription/current");
  },
  getDirectorPendingUpgradeSubscription() {
    return axios.get("/director/subscription/pending-upgrade");
  },
  fetchPastSubscription() {
    return axios.get("/director/subscription/past");
  },
  fetchPastSubsDetails(id) {
    return axios.get("/director/subscription/past/" + id);
  },
  subscriptionCancel(id) {
    return axios.delete("/director/subscription/cancel/" + id);
  },
  // Director
  fetchDirectorLearningCentreList(data) {
    return axios.get(
      "/director/learningCentre/list?page=" +
        data.page +
        "&limit=" +
        data.limit +
        "&search=" +
        data.search +
        "&sort=" +
        data.sort +
        "&dir=" +
        data.dir +
        "&country=" +
        data.country +
        "&state=" +
        data.state +
        "&director=" +
        data.directorId
    );
  },
  //Director

  directorSignup(data) {
    return axios.post("/director/signup", data);
  },
  addRegionalDirector(data) {
    return axios.post("/director/regionalDirector/store", data);
  },
  updateRegionalDirectorData(data) {
    return axios.post("/director/regionalDirector/update", data);
  },
  getRegionalDirectors(data) {
    return axios.get("/director/getRegionalDirectors");
  },
  getRegionalDirectorsList(data) {
    return axios.get(
      "/director/getRegionalDirectorsList?page=" +
        data.page +
        "&limit=" +
        data.limit +
        "&search=" +
        data.search +
        "&sort=" +
        data.sort +
        "&dir=" +
        data.dir +
        "&country=" +
        data.country +
        "&state=" +
        data.state +
        "&director=" +
        data.directorId
    );
  },
  getRegionalDirectorById(directorId) {
    return axios.get("/director/regionalDirector/details/" + directorId);
  },
  removeCenterFromRegionalDirector(data) {
    return axios.get("/director/getRegionalDirectorsList/", data);
  },

  fetchRegionalDirectorsOfLearningCentre(centerId) {
    return axios.get("/director/regionalDirectorsOfCenter/" + centerId);
  },
  fetchRegionalDirectorLearningCentreList(data) {
    return axios.get(
      "/director/regionalDirector/learningCentre/list?page=" +
        data.page +
        "&limit=" +
        data.limit +
        "&search=" +
        data.search +
        "&sort=" +
        data.sort +
        "&dir=" +
        data.dir +
        "&country=" +
        data.country +
        "&state=" +
        data.state +
        "&director=" +
        data.directorId
    );
  },

  /*Center*/
  addDirectorCenter(data) {
    return axios.post("/director/center/store", data);
  },
  updateDirectorCenterData(data) {
    return axios.post("/director/center/update", data);
  },
  deleteDirectorCenter(centerId) {
    return axios.get("/director/center/delete/" + centerId);
  },
  getDirectorCenterDetailsById(centerId) {
    return axios.get("/director/center/details/" + centerId);
  },
  getDirectorCenterAdmin(centerId) {
    return axios.get("/director/center/adminList/" + centerId);
  },
  saveContentByCenterId(data) {
    return axios.post("/centerAdmin/nqs-content/" + data.centerId, data.data);
  },
  getEylfCheckListByCenterId(data) {
    return axios.get("/centerAdmin/eylf-checklist/" + data);
  },
  getContentByCenterId(centerId) {
    return axios.get("/centerAdmin/nqs-content/" + centerId);
  },
  getDirectorCenterNumberList(centerId) {
    return axios.get("/director/center/numberList/" + centerId);
  },
  addDirectorAdminCenter(data) {
    return axios.post(
      "/director/center/addAdmin/" + data.centerId,
      data.details
    );
  },
  getDirectorCenterAdminDetails(id) {
    return axios.get("/director/center/admin/details/" + id);
  },
  editDirectorCenterAdminDetails(data) {
    return axios.post(
      "/director/center/editAdmin/" + data.adminId,
      data.details
    );
  },
  getDirectorCenterTeacherList(data) {
    return axios.get(
      "/director/center/teachers/list/?search=" +
        data.search +
        "&director=" +
        data.id +
        "&sort=" +
        data.sort +
        "&dir=" +
        data.dir +
        "&limit=" +
        data.limit +
        "&page=" +
        data.page
    );
  },
  getDirectorCenterChildrenList(data) {
    return axios.get(
      "/director/center/children/list?search=" +
        data.search +
        "&director=" +
        data.id +
        "&sort=" +
        data.sort +
        "&dir=" +
        data.dir +
        "&limit=" +
        data.limit +
        "&page=" +
        data.page
    );
  },

  httpGetAllLearningCenterListDirector(directorId) {
    return axios.get(
      "/director/getAllLearningCenterListDirector/" + directorId
    );
  },

  getCenterWithRoomInfoDetail(directorId) {
    return axios.get("/director/center-with-rooms-detail/" + directorId);
  },

  getDirectorCenterRoomsList(data) {
    return axios.get(
      "/director/center/director/rooms/list?search=" +
        data.search +
        "&director=" +
        data.id
    );
  },
  getRoomsByCenterIds(data) {
    return axios.get(
      "/director/center/director/rooms-center?centerIds=" + data
    );
  },

  getTeacherByRoomIds(data) {
    return axios.get("/director/get-teacher-rooms?roomIds=" + data);
  },

  getDirectorRoomsList(directorId) {
    return axios.get("/director/get-director-rooms/" + directorId);
  },
  getTeachersByRoomId(roomId) {
    return axios.get("/director/get-room-teachers/" + roomId);
  },
  getTeachersByRoomIdFilter(roomId) {
    return axios.get("/teacher/childfilter/" + roomId);
  },

  /**
   * Teacher
   */
  getDirectorTeacherList(data) {
    return axios.get(
      "/director/teachers/list?page=" +
        data.page +
        "&limit=" +
        data.limit +
        "&search=" +
        data.search +
        "&sort=" +
        data.sort +
        "&dir=" +
        data.dir +
        /*  "&country=" +
       data.country +
       "&state=" +
       data.state + */
        "&director=" +
        data.directorId
    );
  },

  getFilterTeacherList() {
    return axios.get(
      "director/teachers/filter-list"
    );
  },

  getDirectorTeacherDetails(id) {
    return axios.get("/director/teacher/details/" + id);
  },
  editDirectorTeacherDetails(data) {
    return axios.post("/director/teacher/edit/", data);
  },
  deleteDirectorTeacher(teacherId) {
    return axios.get("/director/teacher/delete/" + teacherId);
  },

  /**
   * Children
   */
  getDirectorChildrenList(data) {
    return axios.get(
      "/director/children/list?page=" +
        data.page +
        "&limit=" +
        data.limit +
        "&search=" +
        data.search +
        "&sort=" +
        data.sort +
        "&dir=" +
        data.dir +
        "&director=" +
        data.directorId
    );
  },
  addDirectorChild(data) {
    return axios.post("/director/children/add", data);
  },
  getDirectorChildDetails(id) {
    return axios.get("/director/children/details/" + id);
  },
  editDirectorChildDetails(data) {
    return axios.post("/director/children/edit/", data);
  },
  deleteDirectorChild(id) {
    return axios.get("/director/children/delete/" + id);
  },

  /** parent */
  /*  addDirectorParent(data) {

     return axios.post("/director/teacher/add", data);
   }, */

  /*eylf*/
  getDirectorRooms(directorId) {
    return axios.get("/director/get-all-rooms/" + directorId);
  },
  getAgeGroup() {
    return axios.get("/director/get-all-agegroup/");
  },
  getDirectorParentList(directorId) {
    return axios.get("/director/get-all-parent/" + directorId);
  },
  fetchParentByLearningCenterId(centerId) {
    return axios.get("/director/center-parent/" + centerId);
  },
  getDirectorAllCountries() {
    return axios.get("/director/get-all-countries");
  },
  getDirectorSpecificCountries() {
    return axios.get("/director/get-director-specific-countries");
  },

  /*get Children list by room id*/
  getChildrenByRoomId(roomId) {
    return axios.get("teacher/child/" + roomId);
  },
  getChildrenFilterByRoomId(roomId) {
    return axios.get("teacher/childfilter/" + roomId);
  },
  fetchChildrenByMultipleRooms(query) {
    return axios.get("teacher/child-rooms?query=" + query);
  },
  /*Rooms*/
  getFilteredDirectorCenterRoomsList(data) {
    return axios.get(
      "/director/center/rooms/list?page=" +
        data.page +
        "&limit=" +
        data.limit +
        "&search=" +
        data.search +
        "&sort=" +
        data.sort +
        "&dir=" +
        data.dir +
        "&center=" +
        data.centerId
    );
  },
  getRoomById(roomId) {
    return axios.get("/director/room/" + roomId);
  },

  getAllAgeGroup() {
    return axios.get("/director/get-age-group/");
  },

  saveRoom(data) {
    return axios.post("/director/save-room/", data);
  },

  addDirectorTeacher(data) {
    return axios.post("/director/teacher/add/", data);
  },

  /** about us */
  getDirectorAboutUsList(data) {
    return axios.get("/director/get-about-us-list?search=" + data);
  },
  getLearningCentreDirector() {
    return axios.get("/director/get-center-list");
  },
  saveAboutUsDirector(data) {
    return axios.post("/director/create-about-us", data);
  },
  getAboutUsDetails(id) {
    return axios.get("/director/get-about-us/" + id);
  },
  ImageUpload(data) {
    return axios.post("/director/uploadImage", data.obj, data.header);
  },
  updateAboutUs(data) {
    return axios.post("/director/update-about-us/" + data.id, data.data);
  },

  /** about us */
  getDirectorPhilosophiesList(data) {
    return axios.get("/director/get-philosophies-list?search=" + data);
  },
  savePhilosophiesDirector(data) {
    return axios.post("/director/create-philosophies", data);
  },
  getPhilosophiesDetails(id) {
    return axios.get("/director/get-philosophies/" + id);
  },
  updatePhilosophies(data) {
    return axios.post("/director/update-philosophies/" + data.id, data.data);
  },

  /*Tutorials*/
  fetchTutorials(data) {
    return axios.get(
      "/teacher/tutorials/list?page=" +
        data.page +
        "&limit=" +
        data.limit +
        "&search=" +
        data.search
    );
  },

  getEylfsWithLearningStories(centerId) {
    return axios.get("/teacher/eylf-with-learning-center/" + centerId);
  },
  getDirectorEylfWithLearningStories(query) {
    return axios.get(
      "/teacher/director-eylf-with-learning-center?query=" + query
    );
  },
  geCenterAdminEylfWithLearningStories(query) {
    return axios.get(
      "/teacher/director-eylf-with-learning-center?query=" + query
    );
  },

  getEylfsWithLearningStoriesByRoom(roomId) {
    return axios.get("/teacher/eylf-with-learning-center-by-room/" + roomId);
  },

  saveDailyComment(data) {
    return axios.post("teacher/daily-diary/daily-comment", data);
  },

  editDailyComment(data) {
    return axios.put(
      "teacher/daily-diary/daily-comment/" + data.commentId,
      data
    );
  },

  fetchDailyComment(data) {
    return axios.get(
      "teacher/daily-diary/daily-comment/" +
        data.userId +
        "?date=" +
        data.date +
        "&type=" +
        data.type
    );
  },

  fetchPlannedActiviryForTeacher(data) {
    return axios.get(
      "teacher/daily-diary/daily-comment/" + data.userId + "?date=" + data.date
    );
  },

  // newsletters
  fetchRoomList() {
    return axios.get("director/newsletters/getRoomList");
  },
  createNewsletter(data) {
    return axios.post("director/newsletters/create", data);
  },
  newsletterList(data) {
    return axios.get(
      "/director/newsletters/list?page=" +
        data.page +
        "&limit=" +
        data.limit +
        "&search=" +
        data.search +
        "&sort=" +
        data.sort +
        "&dir=" +
        data.dir +
        "&directorId=" +
        data.directorId
    );
  },
  uploadImage(data) {
    return axios.post(
      "director/newsletters/uploadImage",
      data.obj,
      data.header
    );
  },
  uploadPhotoNewsletterActivity(data) {
    return axios.post(
      "director/newsletters/uploadImage/activity",
      data.obj,
      data.header
    );
  },
  fetchActivities(data) {
    return axios.post("director/newsletters/getActivityList", data);
  },
  newsletterDetails(id) {
    return axios.get("/director/newsletters/details/" + id);
  },
  editNewsletterDetails(data) {
    return axios.post("director/newsletters/edit/" + data.id, data.data);
  },
  editNewsletterActivity(data) {
    return axios.put(
      "director/newsletters/edit/activity/" + data.newsletterId,
      data.data
    );
  },
  removeNewsletterActivityPhoto(data) {
    return axios.delete(
      "director/newsletters/activity/photo/" +
        data.newsletterId +
        "/" +
        data.activityId +
        "?photoId=" +
        data.photoId
    );
  },
  removeActivityFromNewsletter(data) {
    return axios.delete(
      "director/newsletters/activity/" +
        data.newsletterId +
        "/" +
        data.activityId
    );
  },
  sentToParents(data) {
    return axios.post("director/newsletters/sendNewsletter", data);
  },

  resetUserPassword(userId) {
    return axios.get(`user/reset-password/${userId}`);
  },

  getFreeTeacherOfCenter(centerId) {
    return axios.get("director/center/getFreeTeacherOfCenter/" + centerId);
  },
  getFreeChildrenOfCenter(centerId) {
    return axios.get("director/center/getFreeChildrenOfCenter/" + centerId);
  },
  getChildInteraction(data) {
    return axios.get(
      "director/children/activity?roomId=" +
        data.roomId +
        "&childId=" +
        data.childId
    );
  },
  /*Parent*/
  updateParentProfile(data) {
    return axios.post("/parent/update-profile", data);
  },
  fetchGuardians(childId) {
    return axios.get(`/parent/guardian/list/${childId}`);
  },
  removeGuardian(id) {
    return axios.get(`/parent/guardian/delete/${id}`);
  },
  createGuardian(data) {
    return axios.post("/parent/guardian/create", data);
  },
  mailParentRespondFromLearningStory(data) {
    return axios.post("/parent/learning-story/respond", data);
  },
  updateGuardianDetails(data) {
    return axios.post("/parent/guardian/update", data);
  },
  guardianDetail(data) {
    return axios.get(
      `/parent/child/${data.childId}/guardian/${data.guardianId}`
    );
  },
  sendAccessLinkEmail(data) {
    return axios.post(`/parent/send-access-email`, data);
  },
  getInvitedList(childId) {
    return axios.get(`/parent/get-invited-list/${childId}`);
  },
  getTaggedActivityByChildId(childId) {
    return axios.get("/parent/activity/tag-child/" + childId);
  },
  removeParentFromChildAccess(data) {
    return axios.post(`/parent/remove-invited-list/`, data);
  },

  inviteContributor(data) {
    return axios.post(`/parent/invite-contributor/`, data);
  },
  getChildStoryForContributor(contributorId) {
    return axios.get(`/parent/contributor/child-story/` + contributorId);
  },
  saveChildStoryForContributor(data) {
    return axios.post(`/parent/contributor/save-child-story/`, data);
  },
  updateChildStoryForContributor(data) {
    return axios.post(`/parent/contributor/update-child-story/`, data);
  },

  getContributorDetail(contributorId) {
    return axios.get(`/parent/contributor/` + contributorId);
  },
  parentStoreChildStory(data) {
    return axios.post(`/parent/child-story`, data);
  },
  parentUpdateChildStory(data) {
    return axios.put(`/parent/child-story/` + data.storyId, data.data);
  },
  parentDeleteChildStory(storyId) {
    return axios.delete(`/parent/child-story/` + storyId);
  },

  parentDailyComment(data) {
    return axios.get(
      `/parent/daily-comment/` + data.roomId + "?date=" + data.date
    );
  },

  checkGuardianChild(data) {
    return axios.get(
      `/parent/check-child-of-guardian/` + data.childId + "/" + data.parentId
    );
  },

  accessGuardianToChild(data) {
    return axios.post(`/parent/give-access-to-guardian/`, data);
  },

  getDirectorParentRelationToChild(data) {
    return axios.get(
      "director/parent/" + data.parentId + "/get-child-relation/" + data.childId
    );
  },
  /*get completed activity by child id*/
  getCompletedActivityByChildId(data) {
    return axios.get(
      "/parent/activity/child/" +
        data.childId +
        "?page=" +
        data.page +
        "&limit=" +
        data.limit +
        "&startDate=" +
        data.startDate +
        "&endDate=" +
        data.endDate
    );
  },
  /*get parent daily diary*/
  getParentDailyDiary(data) {
    return axios.get("parent/daily-diary/child/" + data.childId + data.date);
  },
  getDailyDiaryByChildId(data) {
    return axios.get("teacher/daily-diary/child/" + data.childId + data.date);
  },
  /*get completed activity by child id*/
  getActivityByActivityIdAndChildId(data) {
    return axios.get(
      "/parent/activity/" + data.activityId + "/child/" + data.childId
    );
  },

  /*get child stories by child id for parent*/
  getParentChildStoryByStoryId(childId) {
    return axios.get("parent/child-story/" + childId);
  },
  /*get child stories presets by learning center id for parent*/
  getParentChildStoryPresetByCenterId(childId) {
    return axios.get(
      "parent/child-story-presets/" + childId + "/learning-center"
    );
  },
  saveInteraction(data) {
    return axios.post("director/children/save/interaction", data);
  },
  getInteraction(childId) {
    return axios.get("director/children/get/interaction/" + childId);
  },

  getDirectorFastChecksDevelopment(filter) {
    return axios.post(
      "/director/professional-development/get-fast-checks-development/",
      filter
    );
  },
  deleteDirectorFastCheckDevelopment(data) {
    return axios.post(
      "/director/professional-development/delete-fast-check-development/",
      data
    );
  },
  fetchDirectorFastChecks() {
    return axios.get("/director/get-fast-checks/");
  },

  /* center admin routes */
  getCentreAdminRoomList(id) {
    return axios.get("/director/dashboard/room/List/" + id);
  },

  fetchAdminActivity(data) {
    return axios.get("/director/dashboard/activity" + data);
  },
  fetchLearningStoryCenterAdmin(params) {
    return axios.get("/director/dashboard/learning-stories-data" + params);
  },
  fetchActiveLearningStoryCenterAdmin(params) {
    return axios.get(
      "/director/dashboard/active-learning-stories-data" + params
    );
  },
  getCentreAdminRooms(id) {
    return axios.get("/centerAdmin/getRooms/" + id);
  },
  getCentreAdminRoomsDetailForNqs(param) {
    return axios.get("/centerAdmin/getRoomsDataForNqs/" + param);
  },
  getCentreAdminRoomsWithTeacher(id) {
    return axios.get("/centerAdmin/getRoomsWithTeacher/" + id);
  },
  saveCenterAdminInteraction(data) {
    return axios.post("/centerAdmin/children/save/interaction", data);
  },
  getCenterAdminInteraction(childId) {
    return axios.get("/centerAdmin/children/get/interaction/" + childId);
  },
  addTeacherCenterAdmin(data) {
    return axios.post("/centerAdmin/teacher/add", data);
  },
  /*Parent news letter*/
  getNewsletterByRoomIdAndParentId(data) {
    return axios.get(
      "parent/newsletters/" +
        data.roomId +
        "/parent/" +
        data.parentId +
        "?page=" +
        data.page +
        "&limit=" +
        data.limit
    );
  },
  getNewsletterByRoomId(data) {
    return axios.get(
      "teacher/newsletters/room/" +
        data.roomId +
        "?page=" +
        data.page +
        "&limit=" +
        data.limit
    );
  },
  getNewsletterDetailById(newsletterId) {
    return axios.get("parent/newsletters/" + newsletterId);
  },
  getTeacherNewsletterDetailById(newsletterId) {
    return axios.get("teacher/newsletters/" + newsletterId);
  },
  getParentAboutUsByCenterId(learningCenterId) {
    return axios.get("/parent/about-us/" + learningCenterId);
  },
  /*
    Parent child story response store
  */
  saveChildStoryResponseParent(data) {
    return axios.post("/parent/child-story/response", data);
  },

  /* center admin routes ENDS */
  /** Rooms */
  deleteDirectorRoom(id) {
    return axios.get("/director/room/delete/" + id);
  },
  updateDirectorRoom(data) {
    return axios.post("/director/room/edit/", data);
  },

  /** Teacher Interaction */
  saveTeacherInteraction(data) {
    return axios.post("teacher/children/save/interaction", data);
  },
  getTeacherInteraction(childId) {
    return axios.get("teacher/children/get/interaction/" + childId);
  },
  getTeacherInteractionFromOtherChild(childId) {
    return axios.get(
      "teacher/children/get/interaction-from-other-child/" + childId
    );
  },

  parentContactForm(data) {
    return axios.post("parent/submit-contact-form", data);
  },

  /*
   Admin Fast Check Questions
 */
  getFastCheckQuestions(presetId) {
    return axios.get("/admin/fast-check-question/" + presetId);
  },

  /*
  Admin Store Fast Check Questions
  */
  saveFastCheckQuestions(data) {
    return axios.post("/admin/fast-check-question/", data);
  },

  /*
   Admin update Fast Check Questions
  */
  editFastCheckQuestions(data) {
    return axios.patch(
      "/admin/fast-check-question/" + data.questionId,
      data.data
    );
  },

  /*
 Admin Delete Fast Check Questions
*/
  removeFastCheckQuestionById(questionId) {
    return axios.delete("/admin/fast-check-question/" + questionId);
  },
  getCoreSkillCount(data) {
    return axios.get("director/core-skill-count?query=" + data);
  },
  getWaysOfLearningCount(data) {
    return axios.get("director/ways-of-learning-count?query=" + data);
  },
  getStreamCount(data) {
    return axios.get("director/stream-count?query=" + data);
  },
  /*
    Get Director Core skill compare
  */
  getDirectorCoreSkillCompare(data) {
    return axios.get("director/core-skill-compare2?query=" + data);
  },
  /*
   Get Director Ways of learning compare
 */
  getDirectorWaysOfLearningCompare(data) {
    return axios.get("director/ways-of-learning-compare2?query=" + data);
  },
  /*
  Get Director Stream compare
*/
  getDirectorStreamCompare(data) {
    return axios.get("director/stream-compare2?query=" + data);
  },

  getSubscriptionPackage() {
    return axios.get("/admin/subscriptionPackageWithFeatures/list");
  },

  /*update status of xero connectivity*/
  updateXeroConnectivityStatus() {
    return axios.get("/xero/check-connection");
  }
};
