/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import Router from "vue-router";
import auth from "@/auth/authService";
import store from "./store/store";
import firebase from "firebase/app";
import "firebase/auth";

import jwt from "./http/requests/auth/jwt/index.js";
import axios from "./http/axios/index.js";

import Swal from "sweetalert2/dist/sweetalert2";
import "sweetalert2/src/sweetalert2.scss";

//var jwt = require('jsonwebtoken');

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================

    {
      path: "/",
      component: () => import("@/layouts/full-page/PersonhoodHomePage.vue"),
      name: "home-page",
      meta: {
        rule: "public"
      }
    },

    { path: "*", redirect: "/page-not-found" },

    /*Invited Contributor*/
    {
      path: "/child-story",
      redirect: "/page-not-found",
      component: () => import("@/layouts/full-page/ContributorFullPage.vue"),
      meta: {
        redirectIfLogin: true,
        rule: "public"
      },
      children: [
        {
          path: "contributor/:id",
          name: "contributor-child-story",
          component: () => import("./views/pages/parent/ChildStoryContributor"),
          meta: {
            authRequired: true,
            pageTitle: "Child Story",
            rule: "public"
          }
        },
        {
          path: "contributor/:id/edit",
          name: "edit-contributor-child-story",
          component: () =>
            import("./views/pages/parent/EditChildStoryContributor"),
          meta: {
            authRequired: true,
            pageTitle: "Child Story",
            rule: "public"
          }
        },
        {
          path: "contributor/:contributorId/child/:childId",
          name: "view-child-story",
          component: () =>
            import("./views/pages/parent/ViewChildStoryContributor"),
          meta: {
            authRequired: true,
            pageTitle: "Child Story",
            rule: "public"
          }
        }
      ]
    },

    /*login and forget password pages*/
    {
      path: "/",
      component: () => import("@/layouts/full-page/PersonhoodFullPage.vue"),
      children: [
        {
          path: "/login",
          name: "front-login",
          component: () => import("@/views/pages/login/Login.vue"),
          props: {},
          meta: {
            redirectIfLogin: true,
            rule: "public"
          }
        },
        {
          path: "/forgot-password",
          name: "forgot-password",
          component: () => import("@/views/auth/ForgotPassword.vue"),
          meta: {
            redirectIfLogin: true,
            rule: "public"
          }
        },
        {
          path: "/reset-password/:token",
          name: "user-reset-password",
          component: () => import("@/views/auth/ResetPassword.vue"),
          meta: {
            redirectIfLogin: true,
            rule: "public"
          }
        }
      ]
    },

    /*generic pages*/
    {
      path: "/",
      redirect: "/home-page",
      component: () => import("@/layouts/full-page/PersonhoodFullPage.vue"),
      children: [
        {
          path: "access-account/parent/:parentId/child/:childId",
          name: "access-child-profile",
          component: () =>
            import("@/views/pages/parent/accessList/AccessSuccess.vue"),
          meta: {
            rule: "public"
          }
        },
        {
          path: "/verify-email/:token",
          name: "user-account-verification",
          beforeEnter: (to, from, next) => {
            const payload = {
              token: to.params.token
            };
            store
              .dispatch("auth/verifyAccount", payload)
              .then(() => {
                next("/login");
              })
              .catch(error => {
                next("/pages/error-419");
              });
          },
          meta: {
            rule: "public"
          }
        },
        {
          path: "/set-password",
          name: "page-set-password",
          component: () => import("@/views/pages/ResetPassword.vue"),
          meta: {
            rule: "public"
          }
        },
        {
          path: "/paypal/success",
          name: "payment-success",
          component: () => import("@/views/pages/paypalSuccess.vue"),
          meta: {
            rule: "public"
          }
        },
        {
          path: "/paypal/failure",
          name: "payment-failure",
          component: () => import("@/views/pages/paypalFailed.vue"),
          meta: {
            rule: "public"
          }
        },

        /*generic pages*/
        {
          path: "/callback",
          name: "auth-callback",
          component: () => import("@/views/Callback.vue"),
          meta: {
            rule: "public"
          }
        },
        {
          path: "page-not-found",
          name: "page-not-found",
          component: () => import("@/views/pages/Error404.vue"),
          meta: {
            rule: "public"
          }
        },
        {
          path: "/not-authorized",
          name: "page-not-authorized",
          component: () => import("@/views/pages/NotAuthorized.vue"),
          meta: {
            rule: "public"
          }
        },
        {
          path: "/pages/lock-screen",
          name: "page-lock-screen",
          component: () => import("@/views/pages/LockScreen.vue"),
          meta: {
            rule: "public"
          }
        },
        {
          path: "/pages/comingsoon",
          name: "page-coming-soon",
          component: () => import("@/views/pages/ComingSoon.vue"),
          meta: {
            rule: "public"
          }
        },
        {
          path: "/pages/error-419",
          name: "page-error-419",
          component: () => import("@/views/pages/Error419.vue"),
          meta: {
            rule: "public"
          }
        },
        {
          path: "/pages/error-500",
          name: "page-error-500",
          component: () => import("@/views/pages/Error500.vue"),
          meta: {
            rule: "public"
          }
        },
        {
          path: "/pages/maintenance",
          name: "page-maintenance",
          component: () => import("@/views/pages/Maintenance.vue"),
          meta: {
            rule: "public"
          }
        },
        {
          path: "/pages/about-us",
          name: "about-us",
          component: () => import("@/views/pages/aboutUs.vue"),
          meta: {
            rule: "public"
          }
        },
        {
          path: "/pages/terms-and-conditions",
          name: "terms-and-conditions",
          component: () => import("@/views/pages/termsAndCondition.vue"),
          meta: {
            rule: "public"
          }
        },
        {
          path: "/pages/privacy-policy",
          name: "privacy-policy",
          component: () => import("@/views/pages/privacyPolicy.vue"),
          meta: {
            rule: "public"
          }
        },
        {
          path: "/director-signup/:type",
          name: "director-signup",
          component: () => import("@/views/pages/cms/directorSignup.vue"),
          meta: {
            rule: "public",
            authRequired: false
          }
        }
      ]
    },

    /*Pricing page*/
    {
      path: "/",
      redirect: "/home-page",
      component: () => import("@/layouts/full-page/PricingPageFullPage.vue"),
      children: [
        {
          path: "/pricing-page",
          name: "pricing-page",
          component: () => import("@/views/pages/cms/pricingPage.vue"),
          meta: {
            rule: "public"
          }
        },
        {
          path: "/form",
          name: "form",
          component: () => import("@/views/pages/cms/Form.vue"),
          meta: {
            rule: "public"
          }
        }
        // {
        //   path: "/payment-details/:type",
        //   name: "payment-details",
        //   component: () => import("@/views/pages/cms/paymentDetails.vue"),
        //   meta: {
        //     authRequired: true,
        //     rule: "public"
        //   }
        // }
      ]
    },

    /*Director payment*/
    {
      path: "/director",
      redirect: "/director/dashboard",
      component: () => import("@/layouts/full-page/PersonhoodFullPage.vue"),
      children: [
        {
          path: "payment-details/:type",
          name: "director-payment-details",
          component: () => import("@/views/pages/cms/paymentDetails.vue"),
          meta: {
            rule: "centreDirector",
            directorOnly: true
          }
        }
      ]
    },

    /*admin Login*/
    {
      path: "/",
      redirect: "admin/login",
      component: () => import("@/layouts/full-page/AdminLoginPage.vue"),
      children: [
        {
          path: "/admin/login",
          name: "admin-login",
          component: () => import("@/views/auth/Login.vue"),
          meta: {
            redirectIfLogin: true,
            rule: "public"
          }
        }
      ]
    },

    /*Center admin start*/
    {
      path: "/center-admin",
      redirect: "/center-admin/dashboard",
      component: () => import("./layouts/main/Main.vue"),
      children: [
        {
          path: "dashboard",
          name: "center-admin-dashboard",
          component: () =>
            import("@/views/pages/center-admin/dashboard/Dashboard.vue"),
          meta: {
            authRequired: true,
            pageTitle: "Dashboard",
            rule: "centerAdmin"
          }
        },
        {
          path: "child-profile/:childId",
          name: "center-child-profile",
          component: () =>
          import("./views/pages/center-admin/child-profile/Profile"),
            //import("./views/pages/center-admin/children/ChildProfile"),
          meta: {
            authRequired: true,
            pageTitle: "Profile",
            rule: "centerAdmin"
          }
        },
        {
          path: "profile",
          name: "center-admin-profile",
          component: () =>
            import("./views/pages/center-admin/profile/UserSettings.vue"),
          meta: {
            authRequired: true,
            pageTitle: "Profile",
            breadcrumb: [
              { title: "Home", url: "/center-admin" },
              { title: "Profile", active: true }
            ],
            rule: "centerAdmin"
          }
        },
        {
          path: "rooms/add/:centerId",
          name: "center-admin-add-rooms",
          component: () => import("./views/pages/center-admin/rooms/addRooms"),
          meta: {
            authRequired: true,
            pageTitle: "Add Room",
            rule: "centerAdmin"
          }
        },
        {
          path: "teacher/add/:centerId",
          name: "center-admin-add-teacher",
          component: () =>
            import("./views/pages/center-admin/teachers/addTeacher"),
          meta: {
            authRequired: true,
            /* breadcrumb: [
               // {title: 'Home', url: '/center-admin/dashboard'},
               {title: 'Add Teacher', active: true},
             ],*/
            pageTitle: "Add Teacher",
            rule: "centerAdmin"
          }
        },
        {
          path: "friendship-circle",
          name: "center-admin-friendship-circle",
          component: () =>
            import("@/views/pages/center-admin/circle/ViewCircle"),
          meta: {
            needPackagePermission: true,
            feature: "dynamic_centre_insights", //friend circle

            authRequired: true,
            pageTitle: "Frienship Circle",
            rule: "centerAdmin"
          }
        },

        /* Center Admin Teacher Starts */
        {
          path: "teachers",
          name: "center-admin-teachers-list",
          component: () =>
            import("./views/pages/center-admin/teachers/listTeachers"),
          meta: {
            pageTitle: "Teachers",
            authRequired: true,
            rule: "centerAdmin"
          }
        },
        {
          path: "teacher/view/:id",
          name: "center-admin-teachers-view",
          component: () =>
            import("./views/pages/center-admin/teachers/teacherView"),
          meta: {
            authRequired: true,
            rule: "centerAdmin"
          }
        },
        {
          path: "teacher/edit/:id",
          name: "center-admin-teachers-edit",
          component: () =>
            import("./views/pages/center-admin/teachers/teacherEdit"),
          meta: {
            authRequired: true,
            rule: "centerAdmin"
          }
        },
        {
          path: "teacher/add",
          name: "center-admin-teacher-add",
          component: () =>
            import("./views/pages/center-admin/teachers/teacherAdd"),
          meta: {
            authRequired: true,
            rule: "centerAdmin"
          }
        },
        {
          path: "nqs-report",
          name: "center-admin-nqs-report",
          component: () => import("./views/pages/center-admin/nqs/Nqs"),
          meta: {
            authRequired: true,
            rule: "centerAdmin",
            pageTitle: "NQS Report"
          }
        },

        /* Center Admin Teacher Ends */

        /* Center Admin  Insight starts */
        {
          path: "insights",
          name: "center-admin-insights",
          component: () =>
            import("./views/pages/center-admin/insight/MyInsight"),
          meta: {
            authRequired: true,
            pageTitle: "Center insights",
            rule: "centerAdmin"
          }
        },
        {
          path: "professional-development",
          name: "center-admin-professional-development",
          component: () =>
            import(
              "./views/pages/center-admin/professional-development/CoreSkills"
            ),
          meta: {
            authRequired: true,
            needPackagePermission: true,
            feature: "professional_development",
            /* breadcrumb: [
               { title: "Home", url: "center-admin/dashboard" },
               { title: "Professional Document", active: true }
             ],*/
            pageTitle: "Professional Document",
            rule: "centerAdmin"
          }
        },
        {
          path: "professional-development/:coreSkillId/:rating",
          name: "center-admin-professional-development-detail",
          component: () =>
            import(
              "./views/pages/center-admin/professional-development/CoreSkills"
            ),
          meta: {
            authRequired: true,
            needPackagePermission: true,
            feature: "professional_development",
            /* breadcrumb: [
               { title: "Home", url: "center-admin/dashboard" },
               { title: "Professional Document", active: true }
             ],*/
            pageTitle: "Professional Document",
            rule: "centerAdmin"
          }
        },
        {
          path: "fast-check/document/:fastCheckPresetId/:rating",
          name: "center-admin-fast-check-document",
          component: () =>
            import(
              "./views/pages/center-admin/insight/fastCheck/FastCheckDocument.vue"
            ),
          meta: {
            authRequired: true,
            needPackagePermission: true,
            feature: "professional_development",
            pageTitle: "Fast Check Document",
            rule: "centerAdmin"
          }
        },

        /* Center Admin  Insight Ends */
        /** ===================================================== */
        /** Center Admin Children Starts */
        {
          path: "children",
          name: "center-admin-children-list",
          component: () =>
            import("./views/pages/center-admin/children/listChildren"),
          meta: {
            pageTitle: "Children",
            authRequired: true,
            rule: "centerAdmin"
          }
        },
        {
          path: "children/view/:id",
          name: "center-admin-children-view",
          component: () =>
            import("./views/pages/center-admin/children/childView"),
          meta: {
            authRequired: true,
            rule: "centerAdmin"
          }
        },
        {
          path: "children/edit/:id",
          name: "center-admin-children-edit",
          component: () =>
            import("./views/pages/center-admin/children/childEdit"),
          meta: {
            authRequired: true,
            rule: "centerAdmin"
          }
        },
        {
          path: "children/add",
          name: "center-admin-children-add",
          component: () =>
            import("./views/pages/center-admin/children/childAdd"),
          meta: {
            authRequired: true,
            rule: "centerAdmin"
          }
        },
        {
          path: "parent/add/:id",
          name: "center-admin-parent-add",
          component: () =>
            import("./views/pages/center-admin/children/parentAdd"),
          meta: {
            authRequired: true,
            rule: "centerAdmin"
          }
        },
        {
          path: "child/:child/parent/:id/edit",
          name: "center-admin-parent-edit",
          component: () =>
            import("./views/pages/center-admin/children/parentEdit"),
          meta: {
            authRequired: true,
            rule: "centerAdmin"
          }
        },

        /** Child Ends */
        /** ================================================ */
        /** room starts */
        {
          path: "rooms",
          name: "center-admin-room-listing",
          component: () => import("./views/pages/center-admin/rooms/ViewList"),
          meta: {
            authRequired: true,
            pageTitle: "View List",
            rule: "centerAdmin"
          }
        },
        {
          path: "learning-centers/rooms/list/:id",
          name: "center-admin-room-list",
          component: () => import("./views/pages/center-admin/rooms/ViewList"),
          meta: {
            authRequired: true,
            pageTitle: "View List",
            rule: "centerAdmin"
          }
        },
        {
          path: "centre/:centerId/room/:id",
          name: "center-admin-room-detail",
          component: () =>
            import("./views/pages/center-admin/rooms/RoomDetail"),
          meta: {
            authRequired: true,
            pageTitle: "Room Detail",
            rule: "centerAdmin"
          }
        },
        {
          path: "learning-center/:centerId/room/add",
          name: "center-admin-add-room",
          component: () => import("./views/pages/center-admin/rooms/addRoom"),
          meta: {
            authRequired: true,
            rule: "centerAdmin",
            pageTitle: "Room"
          }
        },
        {
          path: "learning-center/:centerId/room/:roomId/edit",
          name: "center-admin-edit-room",
          component: () => import("./views/pages/center-admin/rooms/EditRoom"),
          meta: {
            authRequired: true,
            rule: "centerAdmin",
            pageTitle: "Rooms"
          }
        },

        /** Room Ends */
        /** ==================================================== */
        /**
         * newsletter
         * */
        {
          path: "newsletters",
          name: "center-admin-newsletters",
          component: () =>
            import("./views/pages/center-admin/newsletters/listNewsletters"),
          meta: {
            authRequired: true,
            pageTitle: "Newsletters",
            rule: "centerAdmin"
          }
        },
        {
          path: "newsletters/create",
          name: "center-admin-newsletters-create",
          component: () =>
            import("./views/pages/center-admin/newsletters/createNewsletter"),
          meta: {
            authRequired: true,
            pageTitle: "Newsletters",
            rule: "centerAdmin"
          }
        },
        {
          path: "newsletters/view/:id",
          name: "center-admin-newsletters-view",
          component: () =>
            import("./views/pages/center-admin/newsletters/viewNewsletter"),
          meta: {
            authRequired: true,
            pageTitle: "Newsletters",
            rule: "centerAdmin"
          }
        },
        {
          path: "newsletters/edit/:id",
          name: "center-admin-newsletters-edit",
          component: () =>
            import("./views/pages/center-admin/newsletters/editNewsletter"),
          meta: {
            authRequired: true,
            pageTitle: "Newsletters",
            rule: "centerAdmin"
          }
        },
        /**
         * newsletter ends here
         */

        /** professional development start */
        {
          path: "professional-development/fast-check",
          name: "professional-development-center-admin-fast-check",
          component: () =>
            import(
              "./views/pages/center-admin/professional-development/fast-check/fastCheck"
            ),
          meta: {
            authRequired: true,
            needPackagePermission: true,
            feature: "professional_development",

            pageTitle: "Professional Development - Fast Check",
            rule: "centerAdmin"
          }
        },
        {
          path: "log-professional-development/:fastCheckId/:rating",
          name: "center-admin-log-professional-development",
          component: () =>
            import(
              "./views/pages/center-admin/professional-development/fast-check/logProfessionalDevelopment"
            ),
          meta: {
            authRequired: true,

            needPackagePermission: true,
            feature: "professional_development",

            pageTitle: "Professional Document",
            rule: "centerAdmin"
          }
        },
        {
          path: "professional-development/core-skill",
          name: "professional-development-center-admin-core-skill",
          component: () =>
            import(
              "./views/pages/center-admin/professional-development/core-skills/coreSkills.vue"
            ),
          meta: {
            authRequired: true,

            needPackagePermission: true,
            feature: "professional_development",

            pageTitle: "Professional Development - Core Skills",
            rule: "editor"
          }
        },
        /** Professional Development End */

        /** about us start */
        {
          path: "about-us",
          name: "about-us-center-admin",
          component: () =>
            import("./views/pages/center-admin/about-us/about-us-list"),
          meta: {
            authRequired: true,
            pageTitle: "About the centre",
            rule: "editor"
          }
        },
        {
          path: "about-us-create/:id",
          name: "about-us-create-center-admin",
          component: () =>
            import("./views/pages/center-admin/about-us/about-us-create"),
          meta: {
            authRequired: true,
            pageTitle: "Create - About the centre",
            rule: "editor"
          }
        },

        /** support start */
        {
          path: "support",
          name: "support",
          component: () => import("./views/pages/center-admin/support/support"),
          meta: {
            authRequired: true,
            pageTitle: "Support",
            rule: "editor"
          }
        },
        /**** Learning centers philosophies  */
        {
          path: "philosophies",
          name: "center-admin-philosophies",
          component: () =>
            import("./views/pages/center-admin/philosophies/Philoshophy.vue"),
          meta: {
            authRequired: true,
            pageTitle: "Philosophies",
            rule: "editor"
          }
        },
        {
          path: "learning-story",
          name: "center-learning-story",
          component: () =>
            import("./views/pages/center-admin/nqs/LearningStory.vue"),
          meta: {
            authRequired: true,
            pageTitle: "Learning Stories",
            rule: "editor"
          }
        },
        {
          path: "learning-story/activity/:activityId",
          name: "center-learning-story-activity",
          component: () =>
            import("./views/pages/center-admin/nqs/Activity.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Learning Stories",
                url: "/"
              },
              {
                title: "Learning Stories",
                url: "/center-admin/learning-story"
              },
              {
                title: "Details",
                active: true
              }
            ],
            authRequired: true,
            pageTitle: "Learning Stories",
            rule: "editor"
          }
        }
      ]
    },
    /*Center admin end*/

    /*admin start*/
    {
      path: "/admin",
      redirect: "/admin/dashboard",
      component: () => import("./layouts/main/Main.vue"),
      children: [
        {
          path: "dashboard",
          name: "admin-dashboard",
          component: () =>
            import("./views/pages/admin/dashboard/Dashboard.vue"),
          meta: {
            authRequired: true,
            /*breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Dashboard', active: true},
            ],
            pageTitle: 'Dashboard',*/
            rule: "admin"
          }
        },
        /*Admin User Management Start*/
        {
          path: "admin-management",
          name: "list-admin",
          component: () =>
            import("./views/pages/admin/admin-users/UserList.vue"),
          meta: {
            authRequired: true,
            /*breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Admin Management', active: true},
            ],*/
            pageTitle: "Admin Management",
            rule: "superAdmin"
          }
        },
        {
          path: "create-admin",
          name: "create-admin",
          component: () =>
            import("./views/pages/admin/admin-users/UserCreateForm.vue"),
          meta: {
            authRequired: true,
            /*breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Admin Management', url: '/admin/admin-management'},
              {title: 'Add Admin', active: true},
            ],*/
            //pageTitle: 'Admin Management',
            parentPage: "admin-management",
            rule: "superAdmin"
          }
        },
        {
          path: "edit-admin/:id",
          name: "edit-admin",
          component: () =>
            import("./views/pages/admin/admin-users/UserEditForm.vue"),
          meta: {
            authRequired: true,
            /*breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Admin Management', url: '/admin/admin-management'},
              {title: 'Edit Admin', active: true},
            ],*/
            //pageTitle: 'Admin Management',
            parentPage: "admin-management",
            rule: "superAdmin"
          }
        },
        /*Admin User Management End*/
        {
          path: "profile",
          name: "admin-profile",
          component: () =>
            import("./views/pages/admin/profile/UserSettings.vue"),
          meta: {
            authRequired: true,
            breadcrumb: [
              { title: "Home", url: "/admin" },
              { title: "Profile", active: true }
            ],
            pageTitle: "Profile",
            rule: "admin"
          }
        },
        /*Group Management*/
        {
          path: "group-management",
          name: "group-management",
          component: () => import("./views/pages/admin/group/GroupList.vue"),
          meta: {
            pageTitle: "Group Management",
            parentPage: "dashboard",
            rule: "admin"
          }
        },
        {
          path: "/admin/group/view/:id",
          name: "view-group",
          component: () => import("./views/pages/admin/group/GroupView.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/admin" },
              { title: "Group Management", url: "/admin/group-management" },
              { title: "View Group", active: true }
            ],
            pageTitle: "View Group",
            parentPage: "dashboard",
            rule: "admin"
          }
        },

        /*Professional Development Start*/
        {
          path: "professional-development",
          name: "professional-development",
          component: () =>
            import(
              "./views/pages/admin/professional-development/ProfessionalDevelopment.vue"
            ),
          meta: {
            authRequired: true,
            /*breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Professional Development', active: true},
            ],*/
            pageTitle: "Professional Development",
            rule: "admin"
          }
        },
        {
          path: "professional-development/fast-check",
          name: "professional-development-fast-check",
          component: () =>
            import(
              "./views/pages/admin/professional-development/FastCheck.vue"
            ),
          meta: {
            authRequired: true,
            breadcrumb: [
              { title: "Home", url: "/admin" },
              {
                title: "Professional Development",
                url: "/admin/professional-development/"
              },
              { title: "Fast Check", active: true }
            ],
            pageTitle: "Fast Check",
            parentPage: "professional-development",
            rule: "admin"
          }
        },
        {
          path: "professional-development/core-skill",
          name: "professional-development-core-skill",
          component: () =>
            import(
              "./views/pages/admin/professional-development/CoreSkills.vue"
            ),
          meta: {
            authRequired: true,
            breadcrumb: [
              { title: "Home", url: "/admin" },
              {
                title: "Professional Development",
                url: "/admin/professional-development/"
              },
              { title: "Core Skills", active: true }
            ],
            pageTitle: "Core Skills",
            parentPage: "professional-development",
            rule: "admin"
          }
        },
        /*Professional Development End*/

        /*learning Center Presets*/
        {
          path: "learning-center-presets",
          name: "learning center presets",
          component: () =>
            import(
              "./views/pages/admin/learning-centre-present/LearningCenterPreset.vue"
            ),
          meta: {
            authRequired: true,
            /*breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Learning Center Presets', active: true},
            ],*/
            pageTitle: "Learning Center Presets",
            parentPage: "learning-center-presets",
            rule: "admin"
          }
        },
        {
          path: "group/:id/learning-center-presets/assessment-subjects",
          name: "group-assessment-subject",
          component: () =>
            import(
              "./views/pages/admin/learning-centre-present/assessment-subjects/AssessmentSubjects"
            ),
          meta: {
            authRequired: true,
            parentPage: "learning-center-presets",
            rule: "admin"
          }
        },
        {
          path: "learning-center-presets/assessment-subjects",
          name: "learning-center-presets-assessment-subject",
          component: () =>
            import(
              "./views/pages/admin/learning-centre-present/assessment-subjects/AssessmentSubjects"
            ),
          meta: {
            authRequired: true,
            parentPage: "learning-center-presets",
            rule: "admin"
          }
        },
        /*Core Skill Route*/
        {
          path: "learning-center-presets/assessment-subjects/core-skill",
          name: "learning-centre-preset-core-skill",
          component: () =>
            import(
              "./views/pages/admin/learning-centre-present/assessment-subjects/core-skill/CoreSkill"
            ),
          meta: {
            authRequired: true,
            breadcrumb: [
              { title: "Home", url: "/admin" },
              /*{title: 'Learning Center Presets', url: '/admin/learning-center-presets'},*/
              {
                title: "Assessment Subjects",
                url: "/admin/learning-center-presets/assessment-subjects"
              },
              { title: "Core Skill", active: true }
            ],
            pageTitle: "Core Skills",
            parentPage: "learning-center-presets",
            rule: "admin"
          }
        },
        /*Ways of Learning Route*/
        {
          path: "learning-center-presets/assessment-subjects/ways-of-learning",
          name: "learning-centre-preset-core-skill-ways-of-learning",
          component: () =>
            import(
              "./views/pages/admin/learning-centre-present/assessment-subjects/ways-of-learning/WaysOfLearning"
            ),
          meta: {
            authRequired: true,
            breadcrumb: [
              { title: "Home", url: "/admin" },
              //{title: 'Learning Center Presets', url: '/admin/learning-center-presets'},
              {
                title: "Assessment Subjects",
                url: "/admin/learning-center-presets/assessment-subjects"
              },
              { title: "Ways Of Learning", active: true }
            ],
            pageTitle: "Ways Of Learning",
            parentPage: "learning-center-presets",
            rule: "admin"
          }
        },
        /*Stream Route*/
        {
          path: "learning-center-presets/assessment-subjects/stream",
          name: "learning-centre-preset-core-skill-stream",
          component: () =>
            import(
              "./views/pages/admin/learning-centre-present/assessment-subjects/stream/Stream"
            ),
          meta: {
            authRequired: true,
            breadcrumb: [
              { title: "Home", url: "/admin" },
              //{title: 'Learning Center Presets', url: '/admin/learning-center-presets'},
              {
                title: "Assessment Subjects",
                url: "/admin/learning-center-presets/assessment-subjects"
              },
              { title: "Stream", active: true }
            ],
            pageTitle: "Stream",
            parentPage: "learning-center-presets",
            rule: "admin"
          }
        },

        /*Country*/
        {
          path: "/admin/country/",
          name: "country",
          component: () => import("./views/pages/admin/country/Country.vue"),
          meta: {
            authRequired: true,
            pageTitle: "Country",
            rule: "admin"
          }
        },
        /*EYLF Category*/
        {
          path:
            "learning-center-presets/early-years-learning-framework-category",
          name: "learning-center-eylf-category",
          component: () =>
            import(
              "./views/pages/admin/learning-centre-present/early-learning-category/EylfCategory"
            ),
          meta: {
            authRequired: true,
            breadcrumb: [
              { title: "Home", url: "/admin" },
              {
                title: "Learning Center Presets",
                url: "/admin/learning-center-presets"
              },
              { title: "Early Learning Framework Category", active: true }
            ],
            pageTitle: "Early Learning Framework Category",
            parentPage: "learning-center-presets",
            rule: "admin"
          }
        },
        /*EYLF*/
        {
          path: "learning-center-presets/early-years-learning-framework",
          name: "learning-center-presets-eylf",
          component: () =>
            import(
              "./views/pages/admin/learning-centre-present/early-learning-years-framework/EarlyLearningYearsFramework.vue"
            ),
          meta: {
            authRequired: true,
            breadcrumb: [
              { title: "Home", url: "/admin" },
              {
                title: "Learning Center Presets",
                url: "/admin/learning-center-presets"
              },
              { title: "Early Learning Framework", active: true }
            ],
            pageTitle: "Early Learning Framework",
            parentPage: "learning-center-presets",
            rule: "admin"
          }
        },
        {
          path: "learning-center-presets/early-years-learning-framework/:id",
          name: "early-years-learning-framewor",
          component: () =>
            import(
              "./views/pages/admin/learning-centre-present/early-learning-years-framework/ListEarlyLearningYearsFramework.vue"
            ),
          meta: {
            authRequired: true,
            breadcrumb: [
              { title: "Home", url: "/admin" },
              {
                title: "Learning Center Presets",
                url: "/admin/learning-center-presets"
              },
              {
                title: "Early Learning Framework Outcomes",
                url:
                  "/admin/learning-center-presets/early-years-learning-framework"
              },
              { title: "", active: true }
            ],
            //pageTitle: 'ELYF',
            parentPage: "learning-center-presets",
            rule: "admin"
          }
        },
        /*Fast Check*/
        {
          path: "learning-center-presets/fast-check",
          name: "learning-center-presets-fast-check",
          component: () =>
            import(
              "./views/pages/admin/learning-centre-present/fast-check/ListFastCheck.vue"
            ),
          meta: {
            authRequired: true,
            breadcrumb: [
              { title: "Home", url: "/admin" },
              {
                title: "Learning Center Presets",
                url: "/admin/learning-center-presets"
              },
              { title: "Fast Check", active: true }
            ],
            pageTitle: "Fast Check",
            parentPage: "learning-center-presets",
            rule: "admin"
          }
        },

        /*Fast Check Detail*/
        {
          path: "learning-center-presets/fast-check/:id",
          name: "learning-center-presets-fast-check-detail",
          component: () =>
            import(
              "./views/pages/admin/learning-centre-present/fast-check/questions/FastCheckQuestion.vue"
            ),
          meta: {
            authRequired: true,
            breadcrumb: [
              { title: "Home", url: "/admin" },
              {
                title: "Learning Center Presets",
                url: "/admin/learning-center-presets"
              },
              {
                title: "Fast Check",
                url: "/admin/learning-center-presets/fast-check"
              },
              { title: "Fast Check Questions", active: true }
            ],
            pageTitle: "Fast Check Detail",
            parentPage: "learning-center-presets-fast-check",
            rule: "admin"
          }
        },

        /**** Routes for children's stories ****/
        {
          path: "learning-center-presets/children-stories",
          name: "learning-center-presets-children-stories",
          component: () =>
            import(
              "./views/pages/admin/learning-centre-present/childrens-stories/childrenStoriesSetList"
            ),
          meta: {
            authRequired: true,
            breadcrumb: [
              { title: "Home", url: "/admin" },
              {
                title: "Learning Center Presets",
                url: "/admin/learning-center-presets"
              },
              { title: "Children's Stories", active: true }
            ],
            pageTitle: "Children's Stories",
            parentPage: "learning-center-presets",
            rule: "admin"
          }
        },
        {
          path: "learning-center-presets/children-stories/question-list/:id",
          name: "learning-center-presets-children-stories-question-list",
          component: () =>
            import(
              "./views/pages/admin/learning-centre-present/childrens-stories/childrenStories"
            ),
          meta: {
            authRequired: true,
            breadcrumb: [
              { title: "Home", url: "/admin" },
              {
                title: "Learning Center Presets",
                url: "/admin/learning-center-presets"
              },
              {
                title: "Children's Stories",
                url: "/admin/learning-center-presets/children-stories"
              },
              { title: "Children's Stories Questions", active: true }
            ],
            pageTitle: "Children's Stories",
            parentPage: "learning-center-presets",
            rule: "admin"
          }
        },
        {
          path: "tutorial",
          name: "tutorial",
          component: () => import("./views/pages/admin/tutorial/Tutorial"),
          meta: {
            authRequired: true,

            pageTitle: "Tutorial",
            rule: "admin"
          }
        },
        {
          path: "age-group",
          name: "age-group",
          component: () => import("./views/pages/admin/age-groups/AgeGroup"),
          meta: {
            authRequired: true,
            pageTitle: "Age Group",
            rule: "admin"
          }
        },

        /**** Routes for Support page ****/
        {
          path: "support",
          name: "support-list",
          component: () =>
            import("./views/pages/admin/support/supportEnquiryList"),
          meta: {
            authRequired: true,
            breadcrumb: [
              { title: "Home", url: "/admin" },
              { title: "Support", active: true }
            ],
            pageTitle: "Support",
            rule: "admin"
          }
        },
        {
          path: "support/view/:id",
          name: "support-view",
          component: () =>
            import("./views/pages/admin/support/viewSupportEnquiry"),
          meta: {
            authRequired: true,
            breadcrumb: [
              { title: "Home", url: "/admin" },
              { title: "Support", url: "/admin/support" },
              { title: "View", active: true }
            ],
            pageTitle: "Support",
            rule: "admin"
          }
        },
        /**** Routes for Page Management ***/
        {
          path: "pages",
          name: "page-list",
          component: () => import("./views/pages/admin/pages/listPages"),
          meta: {
            authRequired: true,
            breadcrumb: [
              { title: "Home", url: "/admin" },
              { title: "Pages", active: true }
            ],
            pageTitle: "Pages",
            rule: "admin"
          }
        },
        {
          path: "pages/edit/:id",
          name: "page-edit",
          component: () => import("./views/pages/admin/pages/editPage"),
          meta: {
            authRequired: true,
            breadcrumb: [
              { title: "Home", url: "/admin" },
              { title: "Pages", url: "/admin/pages" },
              { title: "Edit", active: true }
            ],
            pageTitle: "Pages",
            rule: "admin"
          }
        },
        /**** Routes for User Subscription ***/
        {
          path: "user-subscriptions",
          name: "user-subscriptions-list",
          component: () =>
            import(
              "./views/pages/admin/user-subscriptions/listUserSubscriptions"
            ),
          meta: {
            authRequired: true,
            breadcrumb: [
              { title: "Home", url: "/admin" },
              { title: "User Subscriptions", active: true }
            ],
            pageTitle: "User Subscriptions",
            rule: "admin"
          }
        },
        {
          path: "user-subscriptions/edit/:id",
          name: "user-subscriptions-edit",
          component: () =>
            import(
              "./views/pages/admin/user-subscriptions/editUserSubscriptions"
            ),
          meta: {
            authRequired: true,
            breadcrumb: [
              { title: "Home", url: "/admin" },
              { title: "User Subscriptions", url: "/admin/user-subscriptions" },
              { title: "Edit", active: true }
            ],
            pageTitle: "User Subscriptions",
            rule: "admin"
          }
        },
        {
          path: "user-subscriptions/view/:id",
          name: "user-subscriptions-view",
          component: () =>
            import(
              "./views/pages/admin/user-subscriptions/viewCustomerDetails"
            ),
          meta: {
            authRequired: true,
            breadcrumb: [
              { title: "Home", url: "/admin" },
              { title: "User Subscriptions", url: "/admin/user-subscriptions" },
              { title: "View", active: true }
            ],
            pageTitle: "User Subscriptions",
            rule: "admin"
          }
        },
        {
          path: "user-subscriptions/past-subscription/:id",
          name: "user-subscriptions-past-subscription",
          component: () =>
            import(
              "./views/pages/admin/user-subscriptions/viewPastSubscription"
            ),
          meta: {
            authRequired: true,
            breadcrumb: [
              { title: "Home", url: "/admin" },
              { title: "User Subscriptions", url: "/admin/user-subscriptions" },
              { title: "Past Subscription", active: true }
            ],
            pageTitle: "User Subscriptions",
            rule: "admin"
          }
        },
        /**** Routes for Learning Centre ****/
        {
          path: "learning-centers",
          name: "learning-centre-list",
          component: () =>
            import("./views/pages/admin/learning-centre/listLearningCentre"),
          meta: {
            authRequired: true,

            pageTitle: "Learning Centre",
            rule: "admin"
          }
        },
        {
          path: "learning-centers/add",
          name: "learning-centre-add",
          component: () =>
            import("./views/pages/admin/learning-centre/addLearningCentre"),
          meta: {
            authRequired: true,
            breadcrumb: [
              { title: "Home", url: "/admin" },
              { title: "Learning Centre", url: "/admin/learning-centers" },
              { title: "Learning Centre Create", active: true }
            ],
            pageTitle: "Learning Centre",
            rule: "admin"
          }
        },
        {
          path: "learning-centers/view/:id",
          name: "learning-centre-view",
          component: () =>
            import(
              "./views/pages/admin/learning-centre/viewLearningCentreDetails"
            ),
          meta: {
            authRequired: true,
            breadcrumb: [
              { title: "Home", url: "/admin" },
              { title: "Learning Centre", url: "/admin/learning-centers" },
              { title: "Learning Centre Details", active: true }
            ],
            pageTitle: "View Centre",
            rule: "admin"
          }
        },
        {
          path: "learning-centers/edit/:id",
          name: "learning-centre-edit",
          component: () =>
            import("./views/pages/admin/learning-centre/editLearningCentre"),
          meta: {
            authRequired: true,
            breadcrumb: [
              { title: "Home", url: "/admin" },
              { title: "Learning Centre", url: "/admin/learning-centers" },
              { title: "Learning Centre Edit", active: true }
            ],
            pageTitle: "Learning Centre",
            rule: "admin"
          }
        },
        {
          path: "learning-centers/:centerId/admin/view/:id",
          name: "learning-centre-admin-view",
          component: () =>
            import("./views/pages/admin/learning-centre/viewAdminDetails"),
          meta: {
            authRequired: true,
            rule: "admin"
          }
        },
        {
          path: "learning-centers/:type/list/:id",
          name: "learning-centre-view-list",
          component: () =>
            import("./views/pages/admin/learning-centre/viewList"),
          meta: {
            authRequired: true,
            breadcrumb: [
              { title: "Home", url: "/admin" },
              { title: "Learning Centre", url: "/admin/learning-centers" },
              { title: "Children List", active: true }
            ],
            pageTitle: "View List",
            rule: "admin"
          }
        },
        /**** Routes for about us */
        {
          path: "about-us",
          name: "about-us-admin",
          component: () => import("./views/pages/admin/about-us/about-us-list"),
          meta: {
            authRequired: true,
            pageTitle: "List - About the centre",
            rule: "editor"
          }
        },
        {
          path: "about-us-details/:id",
          name: "about-us-details-admin",
          component: () =>
            import("./views/pages/admin/about-us/about-us-details"),
          meta: {
            authRequired: true,
            breadcrumb: [
              {
                title: "Home",
                url: "/admin"
              },
              {
                title: "List",
                url: "/admin/about-us"
              },
              {
                title: "Details",
                active: true
              }
            ],
            pageTitle: "About the centre",
            rule: "editor"
          }
        },
        {
          path: "about-us-create",
          name: "about-us-create-admin",
          component: () =>
            import("./views/pages/admin/about-us/about-us-create"),
          meta: {
            authRequired: true,
            pageTitle: "Create - About the centre",
            rule: "editor"
          }
        },

        /**** Learning centers philosophies  */
        {
          path: "philosophies",
          name: "philosophies-admin",
          component: () =>
            import("./views/pages/admin/philosophies/philosophies-list"),
          meta: {
            authRequired: true,
            pageTitle: "List - Philosophies",
            rule: "editor"
          }
        },

        {
          path: "philosophies-details/:id",
          name: "philosophies-details-admin",
          component: () =>
            import("./views/pages/admin/philosophies/philosophies-details"),
          meta: {
            authRequired: true,
            breadcrumb: [
              {
                title: "Home",
                url: "/admin"
              },
              {
                title: "List",
                url: "/admin/philosophies"
              },
              {
                title: "Details",
                active: true
              }
            ],
            pageTitle: "Philosophies",
            rule: "editor"
          }
        },
        {
          path: "philosophies-create",
          name: "philosophies-create-admin",
          component: () =>
            import("./views/pages/admin/philosophies/philosophies-create"),
          meta: {
            authRequired: true,
            pageTitle: "Create - Philosophies ",
            rule: "editor"
          }
        },

        /**** Routes for Settings ****/
        {
          path: "settings/email-template-management",
          name: "email-template-list",
          component: () =>
            import("./views/pages/admin/settings/email-management/listEmail"),
          meta: {
            authRequired: true,
            breadcrumb: [
              { title: "Home", url: "/admin" },
              { title: "Email Template", active: true }
            ],
            pageTitle: "Settings",
            rule: "admin"
          }
        },
        {
          path: "settings/email-template-management-details/:id",
          name: "email-template-details",
          component: () =>
            import("./views/pages/admin/settings/email-management/editEmail"),
          meta: {
            authRequired: true,
            breadcrumb: [
              { title: "Home", url: "/admin" },
              {
                title: "Email Template List",
                url: "/admin/settings/email-template-management"
              },
              { title: "Email Template Details", active: true }
            ],
            pageTitle: "Settings",
            rule: "admin"
          }
        },
        {
          path: "settings/content",
          name: "content-list",
          component: () =>
            import(
              "./views/pages/admin/settings/content-management/listPageContent"
            ),
          meta: {
            authRequired: true,
            breadcrumb: [
              { title: "Home", url: "/admin" },
              { title: "Content", active: true }
            ],
            pageTitle: "Settings",
            rule: "admin"
          }
        },
        {
          path: "settings/unique-selling-point",
          name: "unique-selling-point",
          component: () =>
            import("./views/pages/admin/settings/unique-selling-point/listUsp"),
          meta: {
            authRequired: true,
            breadcrumb: [
              { title: "Home", url: "/admin" },
              { title: "Unique Selling Point", active: true }
            ],
            pageTitle: "Settings",
            rule: "admin"
          }
        },
        {
          path: "settings/testimonials",
          name: "testimonials-list",
          component: () =>
            import(
              "./views/pages/admin/settings/testimonials/listTestimonials"
            ),
          meta: {
            authRequired: true,
            breadcrumb: [
              { title: "Home", url: "/admin" },
              { title: "Testimonial", active: true }
            ],
            pageTitle: "Settings",
            rule: "admin"
          }
        },
        {
          path: "settings/faq",
          name: "faq",
          component: () => import("./views/pages/admin/settings/faq/listFaq"),
          meta: {
            authRequired: true,
            breadcrumb: [
              { title: "Home", url: "/admin" },
              { title: "FAQ", active: true }
            ],
            pageTitle: "Settings",
            rule: "admin"
          }
        },
        {
          path: "settings/subscription-package",
          name: "subscription-package",
          component: () =>
            import(
              "./views/pages/admin/settings/subscription-package/listSubscription"
            ),
          meta: {
            authRequired: true,
            breadcrumb: [
              { title: "Home", url: "/admin" },
              { title: "Subscription Package", active: true }
            ],
            pageTitle: "Settings",
            rule: "admin"
          }
        },
        {
          path: "settings/package-inclusion",
          name: "package-inclusion",
          component: () =>
            import("./views/pages/admin/settings/inclusion/listInclusion"),
          meta: {
            authRequired: true,
            breadcrumb: [
              { title: "Home", url: "/admin" },
              { title: "Package inclusion", active: true }
            ],
            pageTitle: "Settings",
            rule: "admin"
          }
        }
      ]
    },
    /*admin end*/

    // =============================================================================
    // Parent Section Routes
    // =============================================================================
    {
      path: "/parent",
      redirect: "/parent/dashboard",
      component: () => import("./layouts/main/Main.vue"),
      children: [
        {
          path: "profile",
          name: "parent-profile",
          component: () =>
            import("./views/pages/parent/profile/UserSettings.vue"),
          meta: {
            authRequired: true,
            pageTitle: "Profile",
            breadcrumb: [
              { title: "Home", url: "/parent" },
              { title: "Profile", active: true }
            ],
            rule: "parentOrInvitedGuardians"
          }
        },
        {
          path: "dashboard",
          name: "parent-dashboard",
          component: () => import("./views/pages/parent/Dashboard"),
          meta: {
            authRequired: true,
            rule: "parentOrInvitedGuardians"
          }
        },
        {
          path: ":id/child",
          name: "child-detail",
          component: () => import("./views/pages/parent/Dashboard"),
          meta: {
            authRequired: true,
            rule: "parentOrInvitedGuardians"
          }
        },
        {
          path: "guardian/list",
          name: "guardian-list",
          component: () => import("./views/pages/parent/guardian/List"),
          meta: {
            authRequired: true,
            pageTitle: "Guardians",
            rule: "parent"
          }
        },
        {
          path: "guardian/add",
          name: "guardian-add",
          component: () =>
            import("./views/pages/parent/guardian/AddGuardianForm"),
          meta: {
            authRequired: true,
            pageTitle: "Guardians",
            rule: "parent"
          }
        },
        {
          path: "guardian/edit/:id",
          name: "guardian-edit",
          component: () =>
            import("./views/pages/parent/guardian/EditGuardianForm"),
          meta: {
            authRequired: true,
            pageTitle: "Guardians",
            rule: "parent"
          }
        },
        {
          path: "access-list",
          name: "access-list",
          component: () => import("./views/pages/parent/accessList/List"),
          meta: {
            authRequired: true,
            pageTitle: "Profile access",
            rule: "parent"
          }
        },
        {
          path: "access-list/edit",
          name: "access-list-edit",
          component: () => import("./views/pages/parent/accessList/Edit"),
          meta: {
            authRequired: true,
            pageTitle: "Profile access",
            rule: "parent"
          }
        },
        {
          path: "learning-center-philosophies",
          name: "learning-center-philosophies",
          component: () =>
            import("./views/pages/parent/LearningCenterPhilosophies"),
          meta: {
            authRequired: true,
            pageTitle: "",
            rule: "parent"
          }
        },
        {
          path: "about-us",
          name: "parent-about-us",
          component: () => import("./views/pages/parent/ParentAboutUs"),
          meta: {
            authRequired: true,
            pageTitle: "",
            rule: "parent"
          }
        },
        {
          path: "contact",
          name: "parent-contact",
          component: () => import("./views/pages/parent/contact/Contact"),
          meta: {
            authRequired: true,
            pageTitle: "",
            rule: "parent"
          }
        }
      ]
    },

    /*===============Admin Routes End============================*/
    // =============================================================================
    // Teacher Section Routes
    // =============================================================================

    {
      path: "/teacher",
      redirect: "/teacher/dashboard",
      component: () => import("./layouts/main/Main.vue"),
      children: [
        /*Teacher Dashboard*/
        {
          path: "teacher-eylf",
          name: "teacher-eylf",
          component: () => import("./views/pages/teacher/insight/TeacherEylf"),
          meta: {
            authRequired: true,
            pageTitle: "Dashboard",
            rule: "teacher"
          }
        },
        {
          path: "dashboard",
          name: "teacher-dashboard",
          component: () => import("./views/pages/teacher/TeacherDashboard"),
          meta: {
            authRequired: true,
            rule: "teacher"
          }
        },
        /*Teacher Daily Diary*/
        {
          path: "daily-diary",
          name: "Daily Diary",
          component: () =>
            import("./views/pages/teacher/daily-diary/DailyDiary"),
          meta: {
            authRequired: true,
            rule: "teacher"
          }
        },
        /*Teacher Documentation*/
        {
          path: "documentation",
          name: "Documentation",
          component: () =>
            import("./views/pages/teacher/documentation/Documentation"),
          meta: {
            authRequired: true,
            pageTitle: "Documentation",
            rule: "teacher"
          }
        },
        /*Teacher Daily Health Check*/
        {
          path: "daily-health-check",
          name: "Daily Health Check",
          component: () => import("./views/pages/teacher/TeacherDashboard"),
          meta: {
            authRequired: true,
            pageTitle: "Daily Health Check",
            rule: "teacher"
          }
        },
        /*Teacher Self Assessment*/
        {
          path: "self-assessment",
          name: "Self Assessment",
          component: () =>
            import("./views/pages/teacher/self-assessment/SelfAssessment"),
          meta: {
            authRequired: true,
            //pageTitle: 'Self Assessment',
            rule: "teacher"
          }
        },
        /*Teacher Daily Health Check*/
        {
          path: "my-insight",
          name: "my-insight",
          component: () => import("./views/pages/teacher/myInsight/MyInsight"),
          meta: {
            authRequired: true,
            pageTitle: "My Insights",
            rule: "teacher"
          }
        },
        /*Self Assessment*/
        {
          path: "self-assessment",
          name: "self-assessment",
          component: () => import("./views/pages/teacher/TeacherDashboard"),
          meta: {
            authRequired: true,
            rule: "teacher"
          }
        },
        /*Teacher Professional Document Detail*/
        {
          path: "professional-development",
          name: "professional-development",
          component: () =>
            import(
              "./views/pages/admin/professional-development/ProfessionalDevelopment.vue"
            ),
          meta: {
            authRequired: true,
            /*breadcrumb: [
              {title: 'Home', url: '/'},
              {title: 'Professional Development', active: true},
            ],*/
            pageTitle: "Professional Development",
            rule: "teacher"
          }
        },
        {
          path: "professional-development/fast-check",
          name: "professional-development-teacher-fast-check",
          component: () =>
          import(
            "./views/pages/center-admin/professional-development/fast-check/fastCheck"
          ),
          meta: {
            authRequired: true,
            breadcrumb: [
              { title: "Home", url: "/teacher" },
              {
                title: "Professional Development",
                url: "/teacher/professional-development/"
              },
              { title: "Fast Check", active: true }
            ],
            pageTitle: "Fast Check",
            parentPage: "professional-development",
            rule: "teacher"
          }
        },
        {
          path: "professional-development/core-skill",
          name: "professional-development-teacher-core-skill",
          component: () =>
          import(
            "./views/pages/center-admin/professional-development/core-skills/coreSkills.vue"
          ),
          meta: {
            authRequired: true,
            breadcrumb: [
              { title: "Home", url: "/teacher" },
              {
                title: "Professional Development",
                url: "/teacher/professional-development/"
              },
              { title: "Core Skills", active: true }
            ],
            pageTitle: "Core Skills",
            parentPage: "professional-development",
            rule: "teacher"
          }
        },
        {
          path: "professional-development/:coreSkillId/:rating",
          name: "professional-development-detail",
          component: () =>
            import("./views/pages/teacher/professional-development/CoreSkills"),
          meta: {
            authRequired: true,
            breadcrumb: [
              { title: "Home", url: "/teacher" },
              { title: "Self Assessment", url: "/teacher/self-assessment" },
              { title: "Professional Development", active: true }
            ],
            pageTitle: "Professional Development",
            rule: "teacher"
          }
        },
        {
          path: "professional-development-fastcheck/:fastCheckId/:rating",
          name: "professional-development--fastcheck-detail",
          component: () =>
            import("./views/pages/teacher/professional-development/FastChecks"),
          meta: {
            authRequired: true,
            breadcrumb: [
              { title: "Home", url: "/teacher" },
              { title: "Self Assessment", url: "/teacher/self-assessment" },
              { title: "Professional Development", active: true }
            ],
            pageTitle: "Professional Development",
            rule: "teacher"
          }
        },
        {
          path: "fast-check/document/:fastCheckPresetId/:rating",
          name: "fast-check-document",
          component: () =>
            import("./views/pages/teacher/fastCheck/FastCheckDocument.vue"),
          meta: {
            authRequired: true,
            pageTitle: "Fast Check Document",
            rule: "teacher"
          }
        },
        /*Teacher Profile*/
        {
          path: "profile",
          name: "teacher-profile",
          component: () =>
            import("./views/pages/teacher/profile/UserSettings.vue"),
          meta: {
            authRequired: true,
            breadcrumb: [
              { title: "Home", url: "/teacher" },
              { title: "Profile", active: true }
            ],
            pageTitle: "Profile",
            rule: "teacher"
          }
        },
        /*Teacher Tutorial*/
        {
          path: "tutorial",
          name: "teacher-tutorial",
          component: () => import("./views/pages/teacher/tutorial/List.vue"),
          meta: {
            authRequired: true,
            /*breadcrumb: [
              {title: 'Home', url: '/teacher/dashboard'},
              {title: 'Profile', active: true},
            ],*/
            pageTitle: "Tutorials",
            rule: "teacher"
          }
        },
        /*Teacher Child Profile*/
        {
          path: "child-profile/:childId",
          name: "child-profile",
          component: () => import("./views/pages/teacher/child/Profile.vue"),
          meta: {
            authRequired: true,
            /*breadcrumb: [
              {title: 'Home', url: '/teacher/dashboard'},
              {title: 'Profile', active: true},
            ],*/
            pageTitle: "Child Profile",
            rule: "teacher"
          }
        },
        {
          path: "child-story",
          name: "child-story",
          component: () => import("./views/pages/teacher/child/ChildStory.vue"),
          meta: {
            authRequired: true,
            /*breadcrumb: [
              {title: 'Home', url: '/teacher/dashboard'},
              {title: 'Profile', active: true},
            ],*/
            pageTitle: "Your childs story",
            rule: "teacher"
          }
        },
        {
          path: "friendship-circle",
          name: "friendship-circle",
          component: () =>
            import("./views/pages/teacher/circle/ViewCircle.vue"),
          meta: {
            authRequired: true,

            needPackagePermission: true,
            feature: "dynamic_centre_insights", //friend circle

            /*breadcrumb: [
              {title: 'Home', url: '/teacher/dashboard'},
              {title: 'Profile', active: true},
            ],*/
            pageTitle: "Friendship Circle",
            rule: "teacher"
          }
        },
        {
          path: "learning-story",
          name: "teacher-learning-story",
          component: () =>
            import("./views/pages/teacher/learning-story/LearningStory.vue"),
          meta: {
            authRequired: true,
            pageTitle: "Learning Stories",
            rule: "teacher"
          }
        },
        {
          path: "learning-story/activity/:activityId",
          name: "teacher-learning-story-activity",
          component: () =>
            import("./views/pages/teacher/learning-story/Activity.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Learning Stories",
                url: "/"
              },
              {
                title: "Learning Stories",
                url: "/teacher/learning-story"
              },
              {
                title: "Details",
                active: true
              }
            ],
            authRequired: true,
            pageTitle: "Learning Stories",
            rule: "teacher"
          }
        },
        {
          path: "learning-story/activity/edit/:activityId",
          name: "teacher-learning-story-activity-edit",
          component: () =>
            import("./views/pages/teacher/learning-story/Activity.vue"),
          meta: {
            breadcrumb: [
              {
                title: "Learning Stories",
                url: "/"
              },
              {
                title: "Learning Stories",
                url: "/teacher/learning-story"
              },
              {
                title: "Details",
                active: true
              }
            ],
            authRequired: true,
            pageTitle: "Learning Stories",
            rule: "teacher"
          }
        }
      ]
    },

    /*Center director*/
    {
      path: "/director",
      redirect: "/director/dashboard",
      component: () => import("./layouts/main/Main.vue"),
      children: [
        {
          path: "learning-story",
          name: "director-learning-story",
          component: () =>
            import("./views/pages/director/learning-story/LearningStory.vue"),
          meta: {
            authRequired: true,
            pageTitle: "Learning Stories",
            rule: "centreDirector"
          }
        },
        {
          path: "learning-story/activity/:activityId",
          name: "director-learning-story-activity",
          component: () =>
            import("./views/pages/director/learning-story/Activity.vue"),

          meta: {
            breadcrumb: [
              {
                title: "Learning Stories",
                url: "/"
              },
              {
                title: "Learning Stories",
                url: "/director/learning-story"
              },
              {
                title: "Details",
                active: true
              }
            ],
            authRequired: true,
            pageTitle: "Learning Stories",
            rule: "centreDirector"
          }
        },
        {
          path: "dashboard",
          name: "director-dashboard",
          component: () => import("./views/pages/director/DirectorDashboard"),
          meta: {
            authRequired: true,
            rule: "centreDirector"
          }
        },
        {
          path: "centre-dashboard/:centerId",
          name: "director-center-dashboard",
          component: () =>
            import("./views/pages/director/learning-centre/centerDashboard"),
          meta: {
            authRequired: true,
            rule: "centreDirector"
          }
        },
        {
          path: "professional-development/:coreSkillId/:rating",
          name: "director-professional-development-detail",
          component: () =>
            import(
              "./views/pages/director/professional-development/CoreSkills"
            ),
          meta: {
            authRequired: true,
            breadcrumb: [
              { title: "Home", url: "/director" },
              { title: "Professional Development", active: true }
            ],
            pageTitle: "Professional Development",
            rule: "centreDirector"
          }
        },
        {
          path: "payment-details",
          name: "director-payment-details",
          component: () => import("./views/pages/cms/paymentDetails"),
          meta: {
            authRequired: true,
            rule: "centreDirector"
          }
        },
        {
          path: "profile",
          name: "director-profile",
          component: () =>
            import("./views/pages/director/profile/UserSettings.vue"),
          meta: {
            authRequired: true,
            pageTitle: "Profile",
            breadcrumb: [
              { title: "Home", url: "/director" },
              { title: "Profile", active: true }
            ],
            rule: "centreDirector"
          }
        },
        {
          path: "learning-centers",
          name: "director-learning-centre-list",
          component: () =>
            import("./views/pages/director/learning-centre/listLearningCentre"),
          meta: {
            authRequired: true,
            /* breadcrumb: [{
                 title: 'Home',
                 url: '/'
               },
               {
                 title: 'Early Learning Center',
                  active: true
               },

             ],
            pageTitle: 'Manage', */
            pageTitle: "",
            rule: "centreDirector"
          }
        },
        {
          path: "learning-center/add",
          name: "director-learning-centre-add",
          component: () =>
            import("./views/pages/director/learning-centre/learningCentreAdd"),
          meta: {
            authRequired: true,
            /*  breadcrumb: [{
                 title: 'Home',
                 url: '/'
               },
               {
                 title: 'EarlyLearning Centre',
                 active: true
               },
             ],
             pageTitle: 'Add Learning Centre', */
            rule: "centreDirector"
          }
        },

        {
          path: "regional-director/list",
          name: "regional-director-list",
          component: () =>
            import(
              "./views/pages/director/regionalDirector/regionalDirectorList"
            ),
          meta: {
            authRequired: true,
            /*  breadcrumb: [{
                 title: 'Home',
                 url: '/'
               },
               {
                 title: 'EarlyLearning Centre',
                 active: true
               },
             ],
             pageTitle: 'Add Learning Centre', */
            rule: "centreDirector"
          }
        },
        {
          path: "regional-director/add",
          name: "regional-director-add",
          component: () =>
            import(
              "./views/pages/director/regionalDirector/regionalDirectorAdd"
            ),
          meta: {
            authRequired: true,
            /*  breadcrumb: [{
                 title: 'Home',
                 url: '/'
               },
               {
                 title: 'EarlyLearning Centre',
                 active: true
               },
             ],
             pageTitle: 'Add Learning Centre', */
            rule: "centreDirector"
          }
        },
        {
          path: "regional-director/edit/:id",
          name: "regional-director-edit",
          component: () =>
            import(
              "./views/pages/director/regionalDirector/regionalDirectorEdit"
            ),
          meta: {
            authRequired: true,
            /*  breadcrumb: [{
                 title: 'Home',
                 url: '/'
               },
               {
                 title: 'EarlyLearning Centre',
                 active: true
               },
             ],
             pageTitle: 'Add Learning Centre', */
            rule: "centreDirector"
          }
        },
        {
          path: "regional-director/view/:id",
          name: "regional-director-view",
          component: () =>
            import(
              "./views/pages/director/regionalDirector/viewRegionalDirectorDetails"
            ),
          meta: {
            authRequired: true,
            /*  breadcrumb: [{
                 title: 'Home',
                 url: '/'
               },
               {
                 title: 'EarlyLearning Centre',
                 active: true
               },
             ],
             pageTitle: 'Add Learning Centre', */
            rule: "centreDirector"
          }
        },

        {
          path: "learning-centers/edit/:id",
          name: "director-learning-centre-edit",
          component: () =>
            import("./views/pages/director/learning-centre/learningCentreEdit"),
          meta: {
            authRequired: true,
            /* breadcrumb: [{
                  title: 'Home',
                  url: '/'
                },
                {
                  title: 'Learning Centre',
                  url: '/director/learning-centers'
                },
                {
                  title: 'Edit Learning Centre',
                  active: true
                },
              ], */
            pageTitle: "Edit Learning Centre",
            rule: "centreDirector"
          }
        },
        {
          path: "learning-centers/view/:id",
          name: "director-learning-centre-view",
          component: () =>
            import(
              "./views/pages/director/learning-centre/viewLearningCentreDetails"
            ),
          meta: {
            authRequired: true,
            /*  breadcrumb: [{
                title: 'Home',
                url: '/'
              },
              {
                title: 'Learning Centre',
                url: '/director/learning-centers'
              },
              {
                title: 'Learning Centre Details',
                active: true
              },
            ],
            pageTitle: 'View Centre', */
            pageTitle: "",
            rule: "centreDirector"
          }
        },
        {
          path: "learning-centers/admin/view/:id",
          name: "director-learning-centre-admin-view",
          component: () =>
            import("./views/pages/director/learning-centre/viewAdminDetails"),
          meta: {
            authRequired: true,
            /* breadcrumb: [{
              title: 'Home',
              url: '/'
            },
              {
                title: 'Learning Centre',
                url: '/director/learning-centers'
              },
              {
                title: 'Learning Centre Admin Details',
                active: true
              },
            ],
            pageTitle: 'View Admin', */
            pageTitle: "",
            rule: "centreDirector"
          }
        },
        {
          path: "learning-centers/admin/add/:id",
          name: "director-learning-centre-admin-add",
          component: () =>
            import("./views/pages/director/learning-centre/addAdmin"),
          meta: {
            authRequired: true,
            /* breadcrumb: [{
              title: 'Home',
              url: '/'
            },
              {
                title: 'Learning Centre',
                url: '/director/learning-centers'
              },
              {
                title: 'Learning Centre Admin Details',
                active: true
              },
            ],
            pageTitle: 'View Admin', */
            pageTitle: "",
            rule: "centreDirector"
          }
        },
        /*Director room*/
        {
          path: "learning-centers/rooms/list/:id",
          name: "director-room-list",
          component: () => import("./views/pages/director/rooms/ViewList"),
          meta: {
            authRequired: true,
            /* breadcrumb: [{
              title: 'Home',
              url: '/'
            },
              {
                title: 'Learning Centre',
                url: '/director/learning-centers'
              },
              {
                title: 'Room Listing',
                active: true
              },
            ], */
            pageTitle: "View List",
            rule: "centreDirector"
          }
        },
        {
          path: "centre/:centerId/room/:id",
          name: "room-detail",
          component: () => import("./views/pages/director/rooms/RoomDetail"),
          meta: {
            authRequired: true,
            /*breadcrumb: [{
              title: 'Home',
              url: '/'
            },
              {
                title: 'Learning Centre',
                url: '/director/learning-centers'
              },
              {
                title: 'Room Detail',
                active: true
              },
            ],*/
            pageTitle: "Room Detail",
            rule: "centreDirector"
          }
        },
        {
          path: "learning-center/:centerId/room/add",
          name: "director-add-room",
          component: () => import("./views/pages/director/rooms/addRoom"),
          meta: {
            authRequired: true,
            // breadcrumb: [
            //   {
            //     title: "Home",
            //     url: "/director"
            //   },
            //   {
            //     title: "Learning Centre",
            //     url: "/director/learning-centers"
            //   },
            //   {
            //     title: "Add New Room",
            //     active: true
            //   }
            // ],
            pageTitle: "Room",
            rule: "centreDirector"
          }
        },
        {
          path: "learning-center/:centerId/room/:roomId/edit",
          name: "director-edit-room",
          component: () => import("./views/pages/director/rooms/EditRoom"),
          meta: {
            authRequired: true,
            rule: "centreDirector",
            pageTitle: "Edit Room"
          }
        },
        {
          path: "learning-centers/:type/list/:id",
          name: "director-learning-centre-view-list",
          component: () =>
            import("./views/pages/director/learning-centre/viewList"),
          meta: {
            authRequired: true,
            pageTitle: "Manage",
            /* breadcrumb: [{
              title: 'Home',
              url: '/'
            },
              {
                title: 'Learning Centre',
                url: '/director/learning-centers'
              },
              {
                title: 'Learning Centre View List',
                active: true
              },
            ],
            // pageTitle: 'View List', */
            rule: "centreDirector"
          }
        },
        /** Director support start */
        {
          path: "support",
          name: "director-support",
          component: () => import("./views/pages/director/support/support"),
          meta: {
            authRequired: true,
            pageTitle: "Support",
            rule: "editor"
          }
        },
        /* Teacher */
        {
          path: "teachers",
          name: "director-teachers-list",
          component: () =>
            import("./views/pages/director/teachers/listTeachers"),
          meta: {
            authRequired: true,
            /*  breadcrumb: [{
              title: 'Home',
              url: '/'
            },
              {
                title: 'Teachers',
                active: true
              },


            ],
            pageTitle: 'Teachers', */
            rule: "centreDirector"
          }
        },
        {
          path: "teacher/view/:id",
          name: "director-teachers-view",
          component: () =>
            import("./views/pages/director/teachers/teacherView"),
          meta: {
            authRequired: true,
            /*  breadcrumb: [{
              title: 'Home',
              url: '/'
            },
              {
                title: 'Teachers',
                url: '/director/teachers'
              },
              {
                title: 'Teacher Details',
                active: true
              },

            ],
            pageTitle: 'Teacher Detail', */
            rule: "centreDirector"
          }
        },
        {
          path: "teacher/edit/:id",
          name: "director-teachers-edit",
          component: () =>
            import("./views/pages/director/teachers/teacherEdit"),
          meta: {
            authRequired: true,
            /*  breadcrumb: [{
              title: 'Home',
              url: '/'
            },
              {
                title: 'Teachers',
                url: '/director/teachers'
              },
              {
                title: 'Teacher Edit',
                active: true
              },
              ],
              pageTitle: 'Teachers', */
            rule: "centreDirector"
          }
        },
        {
          path: "teacher/add",
          name: "director-teachers-add",
          component: () => import("./views/pages/director/teachers/teacherAdd"),
          meta: {
            authRequired: true,
            /*   breadcrumb: [{
                  title: 'Home',
                  url: '/'
                },
                {
                  title: 'Teachers',
                  url: '/director/teachers'
                },
                {
                  title: 'Add New Teacher',
                  active: true
                },

              ],
              pageTitle: 'Teachers', */
            rule: "centreDirector"
          }
        },
        {
          path: "teacher/add/learning-center/:id",
          name: "director-teacher-add-learning-center",
          component: () => import("./views/pages/director/teachers/teacherAdd"),
          meta: {
            authRequired: true,
            /*  breadcrumb: [{
                  title: 'Home',
                  url: '/'
                },
                {
                  title: 'Teachers',
                  url: '/director/teachers'
                },
                {
                  title: 'Add New Teacher',
                  active: true
                },

              ],
              pageTitle: 'Teachers', */
            rule: "centreDirector"
          }
        },
        /* Children */
        {
          path: "children",
          name: "director-children-list",
          component: () =>
            import("./views/pages/director/children/listChildren"),
          meta: {
            authRequired: true,
            /* breadcrumb: [{
                   title: 'Home',
                   url: '/'
                 },
                 {
                   title: 'Children',
                   active: true
                 },

               ],
               pageTitle: 'Children', */
            rule: "centreDirector"
          }
        },
        {
          path: "children/view/:id",
          name: "director-children-view",
          component: () => import("./views/pages/director/children/childView"),
          meta: {
            authRequired: true,
            /*  breadcrumb: [{
                   title: 'Home',
                   url: '/'
                 },
                 {
                   title: 'Children',
                   url: '/director/children'
                 },
                 {
                   title: 'child Details',
                   active: true
                 },

               ],
               pageTitle: 'Child Detail', */
            rule: "centreDirector"
          }
        },
        {
          path: "children/edit/:id",
          name: "director-children-edit",
          component: () => import("./views/pages/director/children/childEdit"),
          meta: {
            authRequired: true,
            /* breadcrumb: [{
                   title: 'Home',
                   url: '/'
                 },
                 {
                   title: 'Children',
                   url: '/director/children'
                 },
                 {
                   title: 'Child Edit',
                   active: true
                 },

               ],
               pageTitle: 'Child Update', */
            rule: "centreDirector"
          }
        },
        {
          path: "children/add/:id",
          name: "director-children-add",
          component: () => import("./views/pages/director/children/childAdd"),
          meta: {
            authRequired: true,
            /*  breadcrumb: [{
                   title: 'Home',
                   url: '/'
                 },
                 {
                   title: 'Children',
                   url: '/director/children'
                 },
                 {
                   title: 'Add New Child',
                   active: true
                 },

               ],
               pageTitle: 'Children', */
            rule: "centreDirector"
          }
        },
        {
          path: "parent/add/:id",
          name: "director-parent-add",
          component: () => import("./views/pages/director/children/parentAdd"),
          meta: {
            authRequired: true,
            /* breadcrumb: [{
                    title: 'Home',
                    url: '/'
                  },
                  {
                    title: 'Children',
                    url: '/director/children'
                  },
                  {
                    title: 'Add New Child',
                    active: true
                  },

                ],
                pageTitle: 'Add New Parent/guardian', */
            rule: "centreDirector"
          }
        },
        {
          path: "child-profile/:childId",
          name: "director-child-profile",
          component: () =>
            import("./views/pages/director/child-profile/Profile.vue"),
          meta: {
            authRequired: true,
            pageTitle: "Child Profile",
            rule: "centreDirector"
          }
        },
        {
          path: "profile",
          name: "director-profile",
          component: () =>
            import("./views/pages/director/profile/UserSettings.vue"),
          meta: {
            authRequired: true,
            pageTitle: "Profile",
            rule: "centreDirector"
          }
        },
        /*  {
           path: "learning-centre/add",
           name: "learning-centre-add",
           component: () =>
             import("./views/pages/director/learning centre/addCentre"),
           meta: {
             authRequired: true,
             breadcrumb: [
               { title: "Home", url: "/director/dashboard" },
               { title: "Add Centre", active: true }
             ],
             pageTitle: "Learning Centre",
             rule: "editor"
           }
         }, */
        {
          path: "newsletters",
          name: "newsletters",
          component: () =>
            import("./views/pages/director/newsletters/listNewsletters"),
          meta: {
            authRequired: true,
            // breadcrumb: [
            //   { title: "Home", url: "/director/dashboard" },
            //   { title: "List", active: true }
            // ],
            pageTitle: "Newsletters",
            rule: "centreDirector"
          }
        },
        {
          path: "newsletters/create",
          name: "newsletters-create",
          component: () =>
            import("./views/pages/director/newsletters/createNewsletter"),
          meta: {
            authRequired: true,
            pageTitle: "Newsletters",
            rule: "centreDirector"
          }
        },
        {
          path: "newsletters/view/:id",
          name: "newsletters-view",
          component: () =>
            import("./views/pages/director/newsletters/viewNewsletter"),
          meta: {
            authRequired: true,
            pageTitle: "Newsletters",
            rule: "centreDirector"
          }
        },
        {
          path: "newsletters/edit/:id",
          name: "newsletters-edit",
          component: () =>
            import("./views/pages/director/newsletters/editNewsletter"),
          meta: {
            authRequired: true,
            pageTitle: "Newsletters",
            rule: "centreDirector"
          }
        },
        {
          path: "insights",
          name: "insights",
          component: () => import("./views/pages/director/insight/MyInsight"),
          meta: {
            authRequired: true,

            needPackagePermission: true,
            feature: "dynamic_centre_insights",

            pageTitle: "Centre insights",
            rule: "centreDirector",
            breadcrumb: []
          }
        },
        {
          path: "fast-check/document/:fastCheckPresetId/:rating",
          name: "director-fast-check-document",
          component: () =>
            import(
              "./views/pages/director/insight/fastCheck/FastCheckDocument.vue"
            ),
          meta: {
            authRequired: true,
            pageTitle: "Fast Check Document",
            rule: "centreDirector"
          }
        },
        {
          path: "subscription",
          name: "director-subscription-view",
          component: () =>
            import("./views/pages/director/subscription/viewSubscription"),
          meta: {
            authRequired: true,
            // breadcrumb: [
            //   {
            //     title: "Home",
            //     url: "/director/dashboard"
            //   },
            //   {
            //     title: "Subscription",
            //     active: true
            //   }
            // ],
            pageTitle: "Subscriptions",
            rule: "centreDirector"
          }
        },
        {
          path: "past-subscription-details/:id",
          name: "past-subscription-details",
          component: () =>
            import(
              "./views/pages/director/subscription/pastSubscriptionDetails"
            ),
          meta: {
            authRequired: true,
            breadcrumb: [
              { title: "Home", url: "/director/dashboard" },
              { title: "Subscription", url: "/director/subscription" },
              { title: "Past Subscription", active: true }
            ],
            pageTitle: "Subscriptions",
            rule: "centreDirector"
          }
        },
        {
          path: "friendship-circle",
          name: "friendship-circle",
          component: () => import("./views/pages/director/circle/ViewCircle"),
          meta: {
            authRequired: true,
            needPackagePermission: true,
            feature: "dynamic_centre_insights", //friend circle

            /* breadcrumb: [{
                title: 'Home',
                url: '/director/dashboard'
              },
              {
                title: 'friendship circle',
                active: true
              }
            ], */
            pageTitle: "Friendship Circle",
            rule: "centreDirector"
          }
        },
        {
          path: "child/:child/parent/:id/edit",
          name: "director-parent-edit",
          component: () => import("./views/pages/director/children/parentEdit"),
          meta: {
            authRequired: true,
            rule: "centreDirector"
          }
        },
        /** about us start */
        {
          path: "about-us",
          name: "about-us-director",
          component: () =>
            import("./views/pages/director/about-us/about-us-list"),
          meta: {
            authRequired: true,
            pageTitle: "List - About the centre",
            rule: "editor"
          }
        },
        {
          path: "about-us-details/:id",
          name: "about-us-details-director",
          component: () =>
            import("./views/pages/director/about-us/about-us-details"),
          meta: {
            authRequired: true,
            breadcrumb: [
              {
                title: "Home",
                url: "/director/dashboard"
              },
              {
                title: "List",
                url: "/director/about-us"
              },
              {
                title: "Details",
                active: true
              }
            ],
            pageTitle: "About the centre",
            rule: "editor"
          }
        },

        {
          path: "about-us-create",
          name: "about-us-create-director",
          component: () =>
            import("./views/pages/director/about-us/about-us-create"),
          meta: {
            authRequired: true,
            pageTitle: "Create - About the centre",
            rule: "editor"
          }
        },
        //
        /**** Learning centers philosophies  */
        {
          path: "philosophies",
          name: "philosophies-director",
          component: () =>
            import("./views/pages/director/philosophies/philosophies-list"),
          meta: {
            authRequired: true,
            pageTitle: "List - Philosophies",
            rule: "editor"
          }
        },
        {
          path: "philosophies-details/:id",
          name: "philosophies-details-director",
          component: () =>
            import("./views/pages/director/philosophies/philosophies-details"),
          meta: {
            authRequired: true,
            breadcrumb: [
              {
                title: "Home",
                url: "/director/dashboard"
              },
              {
                title: "List",
                url: "/director/philosophies"
              },
              {
                title: "Details",
                active: true
              }
            ],
            pageTitle: "Philosophies",
            rule: "editor"
          }
        },
        {
          path: "philosophies-create",
          name: "philosophies-create-director",
          component: () =>
            import("./views/pages/director/philosophies/philosophies-create"),
          meta: {
            authRequired: true,
            pageTitle: "Create - Philosophies ",
            rule: "editor"
          }
        },
        {
          path: "upgrade-package",
          name: "upgrade-package",
          component: () =>
            import("@/views/pages/director/payment/PaymentUpgrade.vue"),
          meta: {
            authRequired: true,
            rule: "centreDirector",
            pageTitle: "Subscriptions",
            directorOnly: true,

            breadcrumb: [
              {
                title: "Home",
                url: "/director/dashboard"
              },
              {
                title: "Subscriptions",
                url: "/director/subscription"
              },
              {
                title: "Upgrade package",
                active: true
              }
            ]
          }
        }
      ]
    }
  ]
});

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

router.beforeEach(async (to, from, next) => {
  let userInfo = JSON.parse(
    localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}userInfo`)
  );

  /*Redirect if bad url being login*/
  if (auth.isAuthenticated() === true) {
    const firstPath = to.path.split("/")[1];

    console.log(firstPath,"firstPathfirstPathfirstPath")

    let userPermission = "parent";
    let userType = "";

    if (userInfo) {
      userType = userInfo.userType;
      userPermission = userType;
      if (userInfo.userType == "learningCenter") {
        if (userInfo.userRole == "centerAdmin") {
          userPermission = "center-admin";
        } else if (userInfo.userRole == "centreDirector") {
          userPermission = "director";
        }
        userType = userInfo.userRole;
      }

      if (userInfo.userType == "superAdmin") {
        userPermission = "admin";
      } else if (userInfo.userType == "guardians") {
        userPermission = "parent";
      }
    }
    if (
      firstPath == "pricing-page" ||
      firstPath == "home-page" ||
      firstPath == "form" ||
      firstPath == "pages" ||
      firstPath == ""

    ) {
      return next();
    } else if (firstPath != userPermission) {
      switch (userType) {
        case "superAdmin":
        case "admin":
          return next({ path: "/admin/dashboard/" });
        case "centreDirector":
          return next({ path: "/director/dashboard/" });
        case "centerAdmin":
          return next({ path: "/center-admin/dashboard/" });
        case "parent":
          return next({ path: "/parent/dashboard/" });
        case "teacher":
          return next({ path: "/teacher/dashboard/" });
        default:
          return next({ path: "/" });
      }
    }
  }

  /*Redirect ro dashboard if already login*/
  if (to.meta.redirectIfLogin) {
    if (auth.isPersonHoodAuthenticated()) {
      let userType = "";

      if (userInfo) {
        userType = userInfo.userType;

        if (userType == "learningCenter") {
          userType = userInfo.userRole;
        }

        switch (userType) {
          case "superAdmin":
          case "admin":
            return next({ path: "/admin/dashboard/" });
          case "centreDirector":
            return next({ path: "/director/dashboard/" });
          case "centerAdmin":
            return next({ path: "/center-admin/dashboard/" });
          case "parent":
            return next({ path: "/parent/dashboard/" });
          case "teacher":
            return next({ path: "/teacher/dashboard/" });
          default:
            return next({ path: "/" });
        }
      } else {
        return next({ path: "/" });
      }
    }
  }

  /*for prevent redirect utl*/
  if (to.meta.directorOnly) {
    if (auth.isAuthenticated() === false) {
      return next({ path: "/login" });
    }

    if (userInfo.role == "centreDirector") {
      if (userInfo.isRegionalDirector) {
        return next({ path: "/director/dashboard/" });
      }
    } else {
      if (userInfo.role == "leadTeacher" || userInfo.role == "teacher") {
        return next({ path: "/teacher/dashboard/" });
      } else if (userInfo.role == "centerAdmin") {
        return next({ path: "/center-admin/dashboard/" });
      } else if (userInfo.role == "parent") {
        return next({ path: "/parent/dashboard/" });
      } else {
        return next({ path: "/login" });
      }
    }
  }

  /*for prevent redirect utl*/
  if (to.meta.needPackagePermission) {
    //   FEATURES: {
    //     PARENT_PORTAL: 'parent_portal',
    //     ACTIVITY_PLANNER: 'activity_planner',
    //     MOBILE_APP: 'mobile_app',
    //     PROFESSIONAL_DEVELOPMENT: 'professional_development',
    //     NEWSLETTER_CREATION: 'newsletter_creation',
    //     DYNAMIC_CENTRE_INSIGHTS: 'dynamic_centre_insights',
    //     DAILY_DIARY: 'daily_diary_of_children_play',
    //     FULL_ACCESS_TO_CORE_SKILLS: 'full_access_to_core_skills_wellbeing_model',
    //     MORE: 'more',
    // },

    try {
      await resolveCheckUserPermission(userInfo._id, to.meta.feature);
    } catch (error) {
      if (error.response.status == 403) {
        await Swal.fire({
          title: "No Access",
          html: `
                <div>
                  You dont have permission to visit this page. Please upgrade your package.
                </div>`,
          icon: "warning",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
          allowOutsideClick: false
        }).then(result => {

          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            window.location.href = '/login';
            //this.$forceUpdate();
            //return next({ path: "/login" });
            // if (userInfo.role == "leadTeacher" || userInfo.role == "teacher") {
            //   return next({ path: "/teacher/dashboard/" });
            // } else if (userInfo.role == "centerAdmin") {
            //   return next({ path: "/center-admin/dashboard/" });
            // } else if (userInfo.role == "parent") {
            //   return next({ path: "/parent/dashboard/" });
            // } else if (userInfo.role == "centreDirector") {
            //   return next({ path: "/director/upgrade-package/" });
            // } else {
            //   return next({ path: "/login" });
            // }
          } else {
            return next({ path: "/login" });
          }
        });
      }
    }

    // let subscription = "";
    // if (userInfo.role == "centreDirector") {
    //   subscription = await resolveGetDirectorSubscription(userInfo);
    // } else {
    //   if (
    //     userInfo.role == "leadTeacher" ||
    //     userInfo.role == "teacher" ||
    //     userInfo.role == "centerAdmin" ||
    //     userInfo.role == "parent"
    //   ) {
    //     subscription = await resolveGetDirectorSubscriptionByTeacherOrCenterAdminId(
    //       userInfo
    //     );
    //   }
    // }

    // let takenSubscription = subscription.data.data.subscription;

    // if (!takenSubscription) {
    //   showDialogBox = true;
    // }

    // const subscriptionStatus = takenSubscription.status;
    // const features = takenSubscription.subscriptionPackage.featureList;

    // const checkForFeature = features.find(
    //   feature => feature.slug === to.meta.feature
    // );

    //console.log(checkForFeature,"permission")
    // if (
    //   !["active", "first_selected"].includes(subscriptionStatus.toLowerCase())
    // ) {
    //   showDialogBox = true;
    // }
    // if (!checkForFeature) {
    //   showDialogBox = true;
    // }
    // if (!checkForFeature.status) {
    //   showDialogBox = true;
    // }

    // if (showDialogBox) {

    // }
  }

  next();
});

// function resolveGetDirectorSubscriptionByTeacherOrCenterAdminId(userInfo) {
//   return new Promise((resolve, reject) => {
//     axios
//       .get(
//         "/teacher/get-director-subscription-by-teacher-or-centeradmin/" +
//           userInfo._id
//       )
//       .then(response => {
//         resolve(response);
//       })
//       .catch(error => {
//         reject(error);
//       });
//   });
// }

// function resolveGetDirectorSubscription(userInfo) {
//   return new Promise((resolve, reject) => {
//     axios
//       .get("/teacher/get-director-subscription/" + userInfo._id)
//       .then(response => {
//         resolve(response);
//       })
//       .catch(error => {
//         reject(error);
//       });
//   });
// }

function resolveCheckUserPermission(userId, featureSlug) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        "/user/check-user-permission-for-module/" + userId + "/" + featureSlug
      )
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export default router;
